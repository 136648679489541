import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Table, Alert } from "antd";
import CircularsActions from "./CircularsActions";
import { RootState } from "../../App";
import { Circular } from "./models";
import { useDispatch } from "react-redux"

const { Column } = Table;
export default function CircularsTable(props: { circulars: Circular[] }) {
  const { circulars } = props;
  const dispatch = useDispatch()
  const error = useSelector((state: RootState) => state.circulars.error);
  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <Table
      pagination={{ hideOnSinglePage: true }}
      dataSource={[...circulars].reverse().map((circular, index) => ({
        ...circular,
        retailer: circular?.retailer?.name,
        formattedActiveDate: moment(circular.activeDate).format("MMM Do YYYY"),
        formattedTerminationDate: moment(circular.terminationDate).format(
          "MMM Do YYYY"
        ),
      }))}
    >

      <Column title="Circular Name" key="circular" dataIndex={"name"} />
      <Column title="Circular Title" key="circular" dataIndex={"title"} />

      <Column
        title={"Active date"}
        key={"formattedActiveDate"}
        dataIndex={"formattedActiveDate"}
      />
      <Column
        title={"Termination date"}
        key={"formattedTerminationDate"}
        dataIndex={"formattedTerminationDate"}
      />
      <Column title={"Retailer"} key={"name"} dataIndex={"retailer"} />
      <Column
        title={""}
        key={"actions"}
        render={(row) => {
          return <CircularsActions row={row} />;
        }}
      />
    </Table>
  );
}
