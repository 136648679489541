import React from "react";
import styled from "styled-components";
import { CircularPage } from "../models";
import { Button } from "antd";
import { Thumbnail } from "../components/Thumbnail";

interface CircularPageListItemProps {
  circularPage: CircularPage;
  onRemove: Function;
  index: number;
}

const Container = styled.div`
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  max-height: 200px;
  width: 100%;
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ThumbnailContainer = styled.div`
  flex: 0;
`;

export const CircularPageListItem = (props: CircularPageListItemProps) => {
  const { index, circularPage, onRemove } = props;
  return (
    <Container>
      <LeftContainer>
        <h2>{`# ${index + 1}`}</h2>
        <h4>{circularPage.pageImageFileName}</h4>
        <Button type="link" danger onClick={() => onRemove(index)}>
          Remove
        </Button>
      </LeftContainer>
      <ThumbnailContainer>
        <Thumbnail circularPage={circularPage} inListItem />
      </ThumbnailContainer>
    </Container>
  );
};
