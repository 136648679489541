import React from "react";
import { useDispatch } from "react-redux";

import { Button } from "antd";
import { Link } from "react-router-dom";
import DeleteCircularModal from "./DeleteCircularModal";
import { showDeleteCircularModal } from "./slice";

export default function CircularsActions(props: any) {
  const dispatch = useDispatch();
  const { row } = props;

  const handleDeleteClick = () => {
    dispatch(showDeleteCircularModal(row.id));
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {
          <Link to={`circulars/${row.id}/edit`}>
            <Button type="link">Edit</Button>
          </Link>
        }
        <Button type="link" danger onClick={handleDeleteClick}>
          Delete
        </Button>
        <DeleteCircularModal row={row} />
      </div>
    </>
  );
}
