import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteUser, getUsers, postUser, putUser } from "./api";
import { UserModel } from "./models";
import { message } from "antd";

export type RetailerState = {
  admins: UserModel[];
  customers: UserModel[];
  retailers: UserModel[];
  inEditUser: Partial<UserModel>;
  loading: boolean;
  showUserModal: boolean;
  confirmDeleteModal: boolean;
  showDeleteModal: boolean;
  error?: string;
  inEditCurrentUser?: Partial<UserModel>;
};

const initialState = {
  admins: [] as UserModel[],
  customers: [] as UserModel[],
  retailers: [] as UserModel[],
  loading: false,
  showUserModal: false,
  confirmDeleteModal: false,
  showDeleteModal: false,
  error: undefined,
} as RetailerState;

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    toggleUserModal: (state) => {
      state.showUserModal = !state.showUserModal;
      state.error = undefined;
    },
    setInEditUser: (state, action: PayloadAction<Partial<UserModel>>) => {
      state.inEditUser = action.payload;
    },
    toggleDeleteModal: (state) => {
      state.showDeleteModal = !state.showDeleteModal;
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      const payload = action.payload;
      state.admins = payload.admins;
      state.customers = payload.customers;
      state.retailers = payload.retailers;
      state.loading = false;
      state.showUserModal = false;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(postUser.fulfilled, (state) => {
      // state.loading = false;
      state.error = undefined;
      message.success("User added successfully");
    });
    builder.addCase(postUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(postUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });

    builder.addCase(putUser.fulfilled, (state) => {
      // state.loading = false;
      state.error = undefined;

      message.success("User updated successfully");
    });
    builder.addCase(putUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(putUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.loading = false;
      state.showDeleteModal = false;

      message.success("User Deleted");
    });
  },
});
