import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getServices, addService, editService, deleteService, getSubserviceOfService, getServiceByRetailerId } from "./api";
import { Service } from "./models";
import { Subservice } from "../subservices/models";

export type ServiceState = {
  all: Service[];
  retailerService: Service[],
  subserviceList: Subservice[];
  currentService?: Service;
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  showSubserviceOfServiceModal: boolean;
  addServiceLoading: boolean;
  addServiceError: string | null;
  addSubserviceError: string | null;
  showSubserviceOfServiceModalError: string | null;
  addSubserviceLoading: boolean;
  showSubserviceOfServiceModalLoading: boolean;
  showEditModal: string | null;
  editServiceLoading: boolean;
  editServiceError: string | null;
  showDeleteModal: string | null;
  showViewModal: string | null;
  viewServiceError: string | null;
  deleteServiceLoading: boolean;
  deleteServiceError: string | null;
  addSuccess: boolean;
  addError: boolean;
  editSuccess: boolean;
  editError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;

  processDeleteStatus : boolean;
  processDeleteMessage : string;
};

const initialState: ServiceState = {
  all: [],
  retailerService: [],
  subserviceList: [],
  currentService: undefined,
  loading: false,
  error: null,
  addServiceError: null,
  addSubserviceError: null,
  showSubserviceOfServiceModalError: null,
  showAddModal: false,
  showSubserviceOfServiceModal: false,
  showViewModal: null,
  addServiceLoading: false,
  addSubserviceLoading: false,
  showSubserviceOfServiceModalLoading: false,
  showEditModal: null,
  editServiceLoading: false,
  editServiceError: null,
  showDeleteModal: null,
  deleteServiceLoading: false,
  deleteServiceError: null,
  viewServiceError: null,
  addSuccess: false,
  addError: false,
  editSuccess: false,
  editError: false,
  deleteSuccess: false,
  deleteError: false,
  processDeleteStatus : false,
  processDeleteMessage : ""
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setCurrentService: (state, action: PayloadAction<Service>) => {
      state.currentService = action.payload;
      state.editServiceError = null;
    },
    showAddServiceModal: (state) => {
      state.showAddModal = true;
    },
    showSubserviceOfServiceModal: (state, action) => {
      state.showSubserviceOfServiceModal = action.payload;
    },
    hideAddServiceModal: (state) => {
      state.showAddModal = false;
      state.addServiceError = null;
    },
    hideSubserviceOfServiceModal: (state) => {
      state.showSubserviceOfServiceModal = false;
      state.showSubserviceOfServiceModalError = null;
    },
    showEditServicesModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditServiceModal: (state) => {
      state.showEditModal = null;
      state.editServiceError = null;
    },
    showViewServicesModal: (state, action) => {
      state.showViewModal = action.payload;
    },
    hideViewServiceModal: (state) => {
      state.showViewModal = null;
      state.viewServiceError = null;
    },
    showDeleteServicesModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeleteServicesModal: (state) => {
      state.showDeleteModal = null;
      state.deleteServiceError = null;
    },
    resetRetailerService: (state) => {
      state.retailerService = []
    },
    clearServiceStatus: (state) => {
      state.addSuccess = false;
      state.addError = false;
      state.editSuccess = false;
      state.editError = false;
      state.deleteSuccess = false;
      state.deleteError = false;
    },
    processDelete : (state,action)=>{
      state.processDeleteMessage = action.payload.message;
      state.processDeleteStatus = action.payload.status;
    }
  },
  extraReducers: (builder) => {
    /* get retailers */
    builder.addCase(getServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.all = action.payload.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
      state.editServiceError = null;
      state.error = null;
    });
    builder.addCase(getServices.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add services*/
    builder.addCase(addService.pending, (state) => {
      state.addServiceLoading = true;
      state.addServiceError = null;
      state.addSuccess = false;
      state.addError = false;
    });
    builder.addCase(addService.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.addServiceLoading = false;
      state.addServiceError = null;
      state.showAddModal = false;
      state.addSuccess = true;
      state.addError = false;
    });
    builder.addCase(addService.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.addServiceError = action.payload;
        state.addServiceLoading = false;
        state.addSuccess = false;
        state.addError = true;
      }
    });

    /* edit retailer*/
    builder.addCase(editService.pending, (state) => {
      state.editServiceLoading = true;
      state.editServiceError = null;
      state.editSuccess = false;
      state.editError = false;
    });
    builder.addCase(editService.fulfilled, (state, action) => {
      // state.all = [...state.all, action.payload];
      state.all = state.all.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.editServiceLoading = false;
      state.editServiceError = null;
      state.showEditModal = null;
      state.editSuccess = true;
      state.editError = false;
    });
    builder.addCase(editService.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editServiceError = action.payload;
        state.editServiceLoading = false;
        state.editSuccess = false;
        state.editError = true;
      }
    });

    /* delete service*/
    builder.addCase(deleteService.pending, (state) => {
      state.deleteServiceLoading = true;
      state.deleteServiceError = null;
      state.deleteSuccess = false;
      state.deleteError = false;
    });
    builder.addCase(deleteService.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.deleteServiceError = action.payload;
        state.deleteServiceLoading = false;
        state.deleteSuccess = false;
        state.deleteError = true;
      }
    });
    builder.addCase(deleteService.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload);
  
      state.deleteServiceLoading = false;
      state.showDeleteModal = null;
      state.deleteServiceError = null;
      state.deleteSuccess = true;
      state.deleteError = false;
    });
    /* getSubserviceOfService*/
    builder.addCase(getSubserviceOfService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubserviceOfService.fulfilled, (state, action) => {
      state.subserviceList = action.payload;
      state.loading = false;
      state.editServiceError = null;
      state.error = null;
    });


    builder.addCase(getSubserviceOfService.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    builder.addCase(getServiceByRetailerId.pending, (state, action) => {
    });

    builder.addCase(getServiceByRetailerId.fulfilled, (state, action) => {
      state.retailerService = action.payload.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
    });

    builder.addCase(getServiceByRetailerId.rejected, (state, action) => {
      console.log("Payload Rejected : ", action.payload);
    });

  },
});

export const {
  showAddServiceModal,
  hideAddServiceModal,
  showEditServicesModal,
  hideEditServiceModal,
  showDeleteServicesModal,
  hideDeleteServicesModal,
  showViewServicesModal,
  hideViewServiceModal,
  hideSubserviceOfServiceModal,
  showSubserviceOfServiceModal,
  resetRetailerService,
  clearServiceStatus,
  processDelete
} = servicesSlice.actions;
