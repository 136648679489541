import { Subservice, SubserviceWithoutId } from "./models";
import { epicApiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";

interface MyData {
  "subservice": []
}
// get subservices
export const getSubservices = createAsyncThunk(
  "/api/allsubservices",
  async (
    _params: { retailerId?: string },
    thunkAPI
  ): Promise<Subservice[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const subservicesResponse = await epicApiClient.get<MyData>(
        `api/allsubservices`,
        createAuthConfig()
      );
      const x: Subservice[] = subservicesResponse.data["subservice"];
      return x as Subservice[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error getting subservices  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);
// add subservice
export const addSubservice = createAsyncThunk(
  "/api/addsubservice",
  async (
    params: SubserviceWithoutId,
    thunkAPI
  ): Promise<Subservice | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await epicApiClient.post<{ message: string, Subservice: Subservice }>(
        "api/addsubservice",
        params,
        createAuthConfig()
      );

      return retailerResponse.data.Subservice;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while adding subservice  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);
// edit subservice
export const editSubservice = createAsyncThunk(
  "/api/editsubservice",
  async (
    params: Subservice,
    thunkAPI
  ): Promise<Subservice | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const retailerResponse = await epicApiClient.post<{ message: string, Subservice: Subservice }>(
        `api/editsubservice`,
        params,
        createAuthConfig()
      );

      return retailerResponse.data.Subservice;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while editing subservice  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

// delete subservice
export const deleteSubservice = createAsyncThunk(
  "/api/deletesubservice",
  async (
    params: Partial<Subservice>,
    thunkAPI
  ): Promise<string | undefined | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const response = await epicApiClient.post<{ message: string, statusCode: number }>(
        // `api/Retailers/${params.id}`,
        `api/deletesubservice/${params.id}`,
        {},
        createAuthConfig()
      );
      if (response.data.statusCode == 200) {

        return params.id;
      }
      else{
        return thunkAPI.rejectWithValue(response.data.message);
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error while deleting subservice  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);

//getsubservice by service id
export const getSubservicesByServiceId = createAsyncThunk(
  "api/getsubservicedetail",
  async (
    _params: { serviceId?: number },
    thunkAPI
  ): Promise<Subservice[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const subservicesResponse = await epicApiClient.get<MyData>(
        `api/getsubservicedetail/${_params.serviceId}`,
        createAuthConfig()
      );
      const x: Subservice[] = subservicesResponse.data["subservice"];
      return x as Subservice[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue(`Error getting subservices  ${e?.response?.data?.error} ${e?.response?.data?.message}`);
    }
  }
);
