import React, { useState } from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import { CircularPage } from "../models";
import { BASE_URL } from "../../../ApiClient";
import { PreviewModal } from "../components/PreviewModal";

const { Text } = Typography;

interface ThumbnailProps {
  circularPage: CircularPage;
  actions?: {
    name: string;
    //@ts-ignore
    callback: (event: MouseEvent<HTMLElement, MouseEvent>) => void;
  }[];
  inListItem?: boolean;
}

const Container = styled.div`
  border: ${(props) => (props.inlist ? "none" : "1px solid #efefef")};
  box-shadow: ${(props) =>
    props.inlist ? "none" : "2px 4px 12px rgba(0, 0, 0, 0.1)"};
  border-radius: 4px;
  /* margin-bottom: 16px; */
  max-height: 100%;
  width: 100%;
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 100%;
`;

const ButtonsContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0050b366;
  border-radius: 4px;
  top: 0px;
  left: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    margin-bottom: 16px;
  }
`;

export const Thumbnail = (props: ThumbnailProps) => {
  const { circularPage, actions, inListItem } = props;
  const [hovered, setHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handlePreviewClick = () => {
    setShowModal(true);
  };

  const handleModalCancel = () => {
    setShowModal(false);
  };

  return (
    <Container inlist={!!inListItem}>
      <InnerContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          alt=""
          src={`${BASE_URL}api/CircularPages/${circularPage.id}/SmallPageImage`}
          style={{
            maxHeight: inListItem ? "166px" : undefined,
          }}
        />
        {!inListItem && <Text>{circularPage.pageImageFileName}</Text>}
        {hovered && (
          <ButtonsContainer onClick={() => setHovered(false)}>
            <Button onClick={handlePreviewClick}>Preview</Button>
            {actions?.map((action) => (
              <Button
                onClick={() => action.callback(circularPage)}
                key={action.name}
              >
                {action.name}
              </Button>
            ))}
          </ButtonsContainer>
        )}
      </InnerContainer>
      <PreviewModal
        visible={showModal}
        onCancel={handleModalCancel}
        page={circularPage}
      />
    </Container>
  );
};
