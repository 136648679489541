import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { apiClient } from "../../../ApiClient";
import { createAuthConfig } from "../../auth/api";
import { CircularPage } from "../models";
import { RootState } from "../../../App";

export const usePagesForCircular = (
  circularGroupId: string,
  circularId: string
) => {
  const [pages, setPages] = useState<CircularPage[]>([]);
  const [getError, setGetError] = useState("");
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState("");

  const circularGroups = useSelector(
    (state: RootState) => state.circularGroups.all
  );

  useEffect(() => {
    getPagesForCircular();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circularGroups]);

  const getPagesForCircular = async () => {
    setGetError("");
    setGetLoading(true);

    try {
      const res = await apiClient.get<CircularPage[]>(
        `/api/CircularGroups/${circularGroupId}/PagesForCircular/${circularId}`,
        createAuthConfig()
      );
      if (res.data) {
        setPages(res.data);
      }
      setGetLoading(false);
      setGetError("");
    } catch (e) {
      setGetError("Error loading Circular Pages from Circular Group");
      setGetLoading(false);
      setPages([]);
    }
  };

  const postPagesForCircular = async (
    pages: CircularPage[]
  ): Promise<CircularPage[] | void> => {
    setPostLoading(true);
    setPostError("");
    try {
      await apiClient.post<Promise<CircularPage[]> | void>(
        `/api/CircularGroups/${circularGroupId}/PagesForCircular/${circularId}`,
        pages,
        createAuthConfig()
      );
      setPostLoading(false);
      getPagesForCircular();
    } catch (e) {
      setPostError("Error adding Circular Pages to Circular Group");
      setPostLoading(false);
      throw new Error("Error adding Circular Pages to Circular Group");
    }
  };

  useEffect(() => {
    getPagesForCircular();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [
    pages,
    getLoading,
    getError,
    getPagesForCircular,
    postPagesForCircular,
    postLoading,
    postError,
  ] as const;
};
