import React, { useEffect } from "react";
import { Modal, Alert, Divider, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteService, getServices } from "./api";
import { RootState } from "../../App";
import { clearServiceStatus, hideDeleteServicesModal } from "./slice";

export default function DeleteModal(props: {
  row: { name: string; uniqueID: string; notes: string; id: string };
}) {
  const { row } = props;
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.services.showDeleteModal === row.id
  );
  const retailerId = useSelector(
    (state: RootState) => state.retailers.currentRetailer?.id
  );
  const loading = useSelector(
    (state: RootState) => state.services.deleteServiceLoading
  );
  const error = useSelector(
    (state: RootState) => state.services.deleteServiceError
  );
  const { deleteSuccess, deleteError, processDeleteStatus, processDeleteMessage } = useSelector((state: RootState) => state.services);

  useEffect(() => {
    if (deleteSuccess) {
      notification.success({
        message: 'Success Message',
        description: `${row.name} service deleted successfully.`
      })
      dispatch(clearServiceStatus())
      dispatch(hideDeleteServicesModal());
    }
    if (deleteError) {
      notification.error({
        message: 'Error Message',
        description: error
      })
      dispatch(clearServiceStatus())
      dispatch(hideDeleteServicesModal());
    }
  }, [deleteError, deleteSuccess])
  // handle Delete 
  const handleDelete = () => {
    dispatch(deleteService(row));
    dispatch(getServices({ retailerId: retailerId }))
  };

  // handle Cancel
  const handleCancel = () => {
    dispatch(hideDeleteServicesModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Delete Service"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading ? loading : undefined }}
      destroyOnClose={true}
    >
      {
        processDeleteStatus == true ? processDeleteMessage :
          <h3>Are you sure you want to delete {row.name}?</h3>
      }
    </Modal>
  );
}
