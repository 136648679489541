import { createSlice } from "@reduxjs/toolkit";
import { getStores, addStore, editStore, deleteStore } from "./api";
import { Store } from "./models";

export type StoresState = {
  all: Store[];
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addLoading: boolean;
  addError: string | null;
  showEditModal: string | null;
  editLoading: boolean;
  editError: string | null;
  showDeleteModal: string | null;
  deleteLoading: boolean;
  deleteError: string | null;
};

const initialState: StoresState = {
  all: [],
  loading: false,
  error: null,
  showAddModal: false,
  addLoading: false,
  addError: null,
  showEditModal: null,
  editLoading: false,
  editError: null,
  showDeleteModal: null,
  deleteLoading: false,
  deleteError: null,
};

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    showAddStoreModal: (state) => {
      state.showAddModal = true;
    },
    hideAddStoreModal: (state) => {
      state.showAddModal = false;
      state.addError = null;
    },
    showEditStoreModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditStoreModal: (state) => {
      state.showEditModal = null;
      state.editError = null;
    },
    showDeleteStoreModal: (state, action) => {
      state.showDeleteModal = action.payload;
      state.deleteError = null;
    },
    hideDeleteStoreModal: (state) => {
      state.showDeleteModal = null;
    },
  },
  extraReducers: (builder) => {
    /* get stores */
    builder.addCase(getStores.pending, (state) => {
      state.all = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getStores.fulfilled, (state, action) => {
      state.all = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getStores.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add store */
    builder.addCase(addStore.pending, (state) => {
      state.addLoading = true;
      state.addError = null;
    });
    builder.addCase(addStore.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.addLoading = false;
      state.addError = null;
      state.showAddModal = false;
    });
    builder.addCase(addStore.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.addError = action.payload;
        state.addLoading = false;
      }
    });

    /* edit store*/
    builder.addCase(editStore.pending, (state) => {
      state.editLoading = true;
      state.editError = null;
    });
    builder.addCase(editStore.fulfilled, (state, action) => {
      state.all = state.all.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.editLoading = false;
      state.editError = null;
      state.showEditModal = null;
    });
    builder.addCase(editStore.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editError = action.payload;
        state.editLoading = false;
      }
    });

    /* delete store*/
    builder.addCase(deleteStore.pending, (state) => {
      state.deleteLoading = true;
      state.deleteError = null;
    });
    builder.addCase(deleteStore.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.deleteError = action.payload;
        state.deleteLoading = false;
      }
    });
    builder.addCase(deleteStore.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload.id);
      state.deleteLoading = false;
      state.showDeleteModal = null;
      state.deleteError = null;
    });
  },
});

export const {
  showAddStoreModal,
  hideAddStoreModal,
  showEditStoreModal,
  hideEditStoreModal,
  showDeleteStoreModal,
  hideDeleteStoreModal,
} = storesSlice.actions;
