import { Circular } from "./models";
import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { RootState } from "../../App";

export const getCirculars = createAsyncThunk(
  "api/Circulars/Get",
  async (
    _params: { retailerId: string | undefined },
    thunkAPI
  ): Promise<Circular[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      //debugger;
      // console.log('get');
      const res = await apiClient.get<Circular[]>(
        `api/Circulars?retailerId=${
          _params.retailerId ? _params.retailerId : ""
        }`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting circulars");
    }
  }
);
export const getCircularsByid = createAsyncThunk(
  "api/Circulars/Get",
  async (
    _params: { id: string | undefined },
    thunkAPI
  ): Promise<Circular[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      //debugger;
      // console.log('get');
      const res = await apiClient.get<Circular[]>(
        `api/Circulars/${_params.id}`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting circulars");
    }
  }
);
export const editCircular = createAsyncThunk(
  "api/Circulars/Edit",
  async (
    params: Circular,
    thunkAPI
  ): Promise<Circular | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      if (!params.retailer) {
        //debugger;
        params.retailer = (thunkAPI.getState() as RootState).currentRetailer.currentRetailer;
      }
      const res = await apiClient.put<Circular>(
        `api/Circulars/${params.id}`,
        params,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing circular");
    }
  }
);
export const deleteCircular = createAsyncThunk(
  "api/Circular/Delete",
  async (
    params: Circular,
    thunkAPI
  ): Promise<Circular | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.delete<Circular>(
        `api/Circulars/${params.id}`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting circular");
    }
  }
);
