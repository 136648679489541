import React from "react";
import { useDispatch } from "react-redux";
import {
  showEditCircularGroupModal,
  showDeleteCircularGroupModal,
} from "./slice";
import { Button } from "antd";

import EditCircularGroupModal from "./EditCircularGroupModal";
import DeleteCircularGroupModal from "./DeleteCircularGroupModal";
import { CircularGroup } from "./models";

export default function ActionsRow(props: { row: CircularGroup }) {
  const dispatch = useDispatch();
  const { row } = props;

  const handleEditClick = () => {
    dispatch(showEditCircularGroupModal(row.id));
  };

  const handleDeleteClick = () => {
    dispatch(showDeleteCircularGroupModal(row.id));
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="link" onClick={handleEditClick}>
          Edit
        </Button>
        <EditCircularGroupModal row={row} />

        <Button type="link" danger onClick={handleDeleteClick}>
          Delete
        </Button>
        <DeleteCircularGroupModal row={row} />
      </div>
    </>
  );
}
