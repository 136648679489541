import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Alert,
  Divider,
  Select,
  Radio,
  DatePicker,
  Button,
  TimePicker,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import { Col, Row } from "antd";
import { hideViewConnectionModal } from "./slice";
import { slug } from "../common/utils/slug";
import moment, { Moment } from "moment";
import { RadioChangeEvent } from "antd/lib/radio";
import { DatePickerProps } from "antd/lib/date-picker";
import { Service } from "../services/models";
import { Properties } from "../properties/models";
import { Subservice } from "../subservices/models";
import { BASE_URL } from "../../ApiClient";
import { getPropertiesBySubServiceId } from "../properties/api";

export default function ViewModal(props: {
  row: {
    name: string;
    id: string;
    description: string;
    retailerID: string;
    serviceID: string;
    subserviceID: string;
    propertyID: string;
    spreadsheetURL: string;
    selectedDateRange: string;
    lastCount: string;
    lastCountType: string;
    fromDate: string;
    toDate: string;
    beginningCount: string;
    beginningType: string;
    beginningDate: string;
    endingCount: string;
    endingType: string;
    endingDate: string;
    selectedInterval: string;
    everyTimeCount: string;
    everyTimeType: string;
    everyWeek: string;
    everyWeekAtTime: string;
    everyDayCount: string;
    everyDayDayType: string;
    everyDayMonthOrYearType: string;
    appendOrReplace: string;
    pageName: string;
    connectuserId: number;
  };
}) {
  const { row } = props;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [appendOrReplace, setAppendOrReplace] = React.useState("append");

  const visible = useSelector(
    (state: RootState) => state.connections.showViewModal === row.id
  );
  const loading = useSelector(
    (state: RootState) => state.connections.editConnectionLoading
  );
  const error = useSelector(
    (state: RootState) => state.connections.editConnectionError
  );

  const retailers = useSelector((state: RootState) => state.retailers.all);

  const [subserviceOptions, setSubserviceOptions] = useState<Subservice[]>([]);
  const services = useSelector((state: RootState) => state.services.all);
  const [retailerServices, setRetailerServices] = useState<Service[]>([]);
  const [subserviceArray, setSubserviceArray] = useState<string[]>([]);
  const [propertyOptions, setPropertyOptions] = useState<Properties[]>([]);
  const property = useSelector(
    (state: RootState) => state.properties.propertyBySubService
  );
  //  useEffect(()=>console.log(property,"hellllo")
  //  ,[])

  // Handle Cancel
  useEffect(() => {
    setPropertyOptions(property);
  }, [property]);

  const handleCancel = () => {
    setRetailerServices([]);
    setSubserviceOptions([]);
    setPropertyOptions([]);

    dispatch(hideViewConnectionModal());
  };

  // Handle Form Change
  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  useEffect(() => {
    (async () => {
      let data = retailers.filter((data) => data.name === row.retailerID);
      let id = data[0].id;
      await setServices(id, false);
    })();
  }, [services]);

  useEffect(() => {
    (async () => {
      await setSubserviceData(Number(row.serviceID), false);
      form.setFieldsValue({ serviceID:  row.serviceID })
      { console.log("row.serviceID", row.serviceID) }
    })();
  }, [subserviceArray]);

  const setServices = async (retailerId: string, clear: boolean = true) => {
    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([]);
      setPropertyOptions([]);
      form.setFieldsValue({ serviceID: "" });
      form.setFieldsValue({ subserviceID: "" });
      form.setFieldsValue({ propertyID: "" });
    }
    const result = await fetch(`${BASE_URL}api/Retailers/${retailerId}`);
    let data = await result.json();

    const serviceArray = data.service?.trim().split(",");
    const RetsubserviceArray = data.subservice?.trim().split(",");

    if (RetsubserviceArray) {
      setSubserviceArray(RetsubserviceArray);
    } else {
      setSubserviceArray([]);
    }

    if (serviceArray) {
      let serviceData = services.filter((item) =>
        serviceArray.includes(item.id.toString())
      );
      if (serviceData.length) {
        setRetailerServices(serviceData);
      }
    } else {
    }
  };

  const setSubserviceData = async (serviceId: number, clear = true) => {
    if (clear) {
      form.setFieldsValue({ subserviceID: "", propertyID: "" });
      setSubserviceOptions([]);
      setPropertyOptions([]);
    }
    if (Number(serviceId) > 0) {
      const result = await fetch(
        `${BASE_URL}api/getsubservicedetail/${serviceId}`
      );
      const data = await result.json();
      let subServices = data.subservice.filter((item: any) =>
        subserviceArray.includes(item.id.toString())
      );

      setSubserviceOptions(subServices);
      if (!subServices.length) {
        let retailer = retailers.filter((data) => data.name === row.retailerID);
        let id = retailer[0].id;
        const result = await fetch(
          `${BASE_URL}api/GetPropertiesDetailserviceID/${serviceId}/${id}`
        );
        const data = await result.json();
        setPropertyOptions(data.property);
      } else {
        if (Number(row.subserviceID) > 0)
          dispatch(
            getPropertiesBySubServiceId({
              subserviceID: Number(row.subserviceID),
            })
          );
      }
    }

    if (serviceId > 0) form.setFieldsValue({ serviceID: serviceId });
    else form.setFieldsValue({ serviceID: "" });

    form.setFieldsValue({
      subserviceID: row.subserviceID,
      propertyID: row.propertyID,
    });
  };
  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onChangeAppendType = (e: RadioChangeEvent) => {
    setAppendOrReplace(e.target.value);
  };

  return (
    <Modal
      maskClosable={false}
      title="View Connection"
      visible={visible}
      cancelText="Close"
      onOk={handleCancel}
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name={`viewConnection#${row.id}`}
        initialValues={{
          name: row.name,
          retailerID: row.retailerID,
          serviceID:
            retailerServices?.length > 0 && row.serviceID !== null
              ? row.serviceID
              : "",
          subserviceID:
            subserviceOptions.length > 0 && row.subserviceID !== null
              ? row.subserviceID
              : "",
          propertyID:
            propertyOptions.length > 0 && row.propertyID !== null
              ? row.propertyID
              : "",
          spreadsheetURL: row.spreadsheetURL,
          selectedDateRange: row.selectedDateRange,
          lastCount: row.lastCount,
          lastCountType: row.lastCountType,
          fromDate: row.fromDate,
          toDate: row.toDate,
          beginningCount: row.beginningCount,
          beginningType: row.beginningType,
          beginningDate: row.beginningDate,
          endingCount: row.endingCount,
          endingType: row.endingType,
          endingDate: row.endingDate,
          selectedInterval: row.selectedInterval,
          everyTimeCount: row.everyTimeCount,
          everyWeek: row.everyWeek,
          everyWeekAtTime: row.everyWeekAtTime,
          everyDayCount: row.everyDayCount,
          everyDayDayType: row.everyDayDayType,
          everyDayMonthOrYearType: row.everyDayMonthOrYearType,
          appendOrReplace: row.appendOrReplace,
          pageName: row.pageName,
          description: row.description,
          connectuserId: row.connectuserId
        }}
        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Connection name"
          name="name"
          rules={[{ required: true, message: "Connection name is required!" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea disabled />
        </Form.Item>
        {/* <Form.Item
                    label="Facebook page name"
                    name="pageName"
                    rules={[{ required: true, message: "Page name is required!" }]}
                >
                    <Input disabled />
                </Form.Item> */}
        <Form.Item
          label="Retailer "
          name="retailerID"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Input disabled />
        </Form.Item>
        {/* ---------------------- */}
        <Row style={{ justifyContent: "space-around" }}>
          <Col
            span={11}
            style={{
              border: "groove",
              borderRadius: "20px",
              borderWidth: "thin",
              padding: "15px",
            }}
          >
            <Form.Item
              label="Property "
              name="propertyID"
            // rules={[{ required: true, message: "Property is required!" }]}
            >
              <Select disabled={true}>
                {propertyOptions?.map((option) => (
                  <Select.Option key={option.id} value={option.id.toString()}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={11}
            style={{
              border: "groove",
              borderRadius: "20px",
              borderWidth: "thin",
              padding: "10px",
            }}
          >
            <Form.Item
              label="Service "
              name="serviceID"
            // rules={[{ required: true, message: "Service is required!" }]}
            >
              {/* <Input disabled /> */}
              <Select
                disabled={true}
              // value={row.serviceID}
              >
                {retailerServices?.length > 0
                  ? retailerServices.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.name}
                    </Select.Option>
                  ))
                  : null}
              </Select>
            </Form.Item>
            <Form.Item
              label="Subservice "
              name="subserviceID"
            // rules={[{ required: true, message: "Subservice is required!" }]}
            >
              <Select disabled={true}>
                {subserviceOptions.length > 0 &&
                  subserviceOptions.map((option) => (
                    <Select.Option key={option.id} value={option.id.toString()}>
                      {option.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          style={{ padding: "20px" }}
          label="Connect as User"
          name="connectuserId"
        >
          <Input disabled />
        </Form.Item>
        {/* <Form.Item
                    label="Property "
                    name="propertyID"
                rules={[{ required: true, message: "Property is required!" }]}
                >
                    <Select
                        disabled={true}>
                        {propertyOptions?.map((option) => (
                            <Select.Option key={option.id} value={option.id.toString()}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Service "
                    name="serviceID"
                rules={[{ required: true, message: "Service is required!" }]}
                >
                    <Input disabled />
                    <Select
                        disabled={true}
                    value={row.serviceID}
                    >
                        {retailerServices?.length > 0
                            ? retailerServices.map((option) => (
                                <Select.Option key={option.id} value={option.id}>
                                    {option.name}
                                </Select.Option>
                            ))
                            : null}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Subservice "
                    name="subserviceID"
                rules={[{ required: true, message: "Subservice is required!" }]}
                >
                    <Select
                        disabled={true}
                    >
                        {subserviceOptions.length > 0 && subserviceOptions.map((option) => (
                            <Select.Option key={option.id} value={option.id.toString()}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item> */}

        <div>
          <label>
            <b>Date Range</b>
          </label>
          {row.selectedDateRange == "1" ? (
            <>
              <p>
                <b>Last</b> &nbsp;{row.lastCount}&nbsp;{row.lastCountType}
              </p>
            </>
          ) : row.selectedDateRange == "2" ? (
            <>
              <p>
                <b>From:</b> &nbsp;{row.fromDate.slice(0, 10)}&nbsp; &nbsp;
                <b>To:</b> &nbsp;{row.toDate.slice(0, 10)}
              </p>
            </>
          ) : row.selectedDateRange == "3" ? (
            <>
              <p>
                {row.beginningCount} &nbsp;{row.beginningType}&nbsp;{" "}
                <b>Beginning on:</b> &nbsp; &nbsp;
                {row.beginningDate.slice(0, 10)}
              </p>
            </>
          ) : (
            <>
              <p>
                {row.endingCount} &nbsp;{row.endingType}&nbsp; <b>Ending on:</b>{" "}
                &nbsp; &nbsp;{row.endingDate.slice(0, 10)}
              </p>
            </>
          )}
        </div>
        <div>
          <label>
            <b>Connection Interval</b>
          </label>
          {row.selectedInterval == "1" ? (
            <>
              {Number(row.everyDayCount) > 0 && row.everyTimeType !== "string" && (
                <p>
                  <b>Every:</b> &nbsp;{row.everyTimeCount} &nbsp;{" "}
                  {row.everyTimeType}
                </p>
              )}
            </>
          ) : row.selectedInterval == "2" ? (
            <>
              <p>
                <b>Every:</b> &nbsp;{row.everyWeek}&nbsp; &nbsp;<b>At:</b>{" "}
                &nbsp;{moment(row.everyWeekAtTime).format("HH:mm")}
              </p>
            </>
          ) : (
            <>
              <p>
                <b>Every:</b> &nbsp; {row.everyDayCount} &nbsp;
                <b>st/nd/rd/th</b> &nbsp;&nbsp;{" "}
                {row.everyDayDayType !== "string" ? row.everyDayDayType : ""}
                &nbsp;&nbsp; <b>of:</b> &nbsp;
                {row.everyDayMonthOrYearType !== "string"
                  ? row.everyDayMonthOrYearType
                  : ""}
              </p>
            </>
          )}
        </div>
        <Form.Item
          rules={[{ required: true, message: "Sheet URL is required!" }]}
          label="Push results to sheet:"
          name="spreadsheetURL"
        >
          <Input disabled />
        </Form.Item>
        <div style={{ textAlign: "center", marginTop: "-10px" }}>
          {" "}
          <Button target="_blank" href={row.spreadsheetURL}>
            Open Sheet
          </Button>
        </div>
        <div>
          <label>Replace or Append Data</label>
          <Form.Item name="appendOrReplace">
            <Radio.Group
              onChange={onChangeAppendType}
              value={appendOrReplace}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Radio
                disabled
                checked
                value={"append"}
                style={{ marginTop: "1em" }}
              >
                Append Data
              </Radio>
              <Radio disabled value={"replace"} style={{ marginTop: "1em" }}>
                Replace Data
              </Radio>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item>
          <Radio.Group
            style={{ display: "flex", flexDirection: "column", width: 600, flexWrap: "wrap", alignContent: "center" }}
          >
            <Radio style={{ marginTop: "-2em", marginRight: "12em" }} value="1st Run at Interval" checked >
              1st Run at Interval
            </Radio>
            <Radio style={{ marginTop: "0.5em" }} value="1st Run Immediate, then at Intervals" disabled>
              1st Run Immediate, <br /> then at Intervals
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Radio.Group
            style={{ display: "flex", flexDirection: "column", flexWrap: "wrap", alignContent: "center" }}
          >
            <Radio style={{ marginTop: "-3em", marginLeft: "1em" }} value="Run Once"
              checked
            >Run Once
            </Radio>
            <Radio style={{ marginTop: "0.5em", marginLeft: "1em" }} value="Save as Template" disabled>Save as Template
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Button onClick={handleCancel}>Cancel</Button>
      </Form>
    </Modal>
  );
}
