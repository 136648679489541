import React, { useEffect } from "react";
import { Modal, Form, Input, Divider, Alert, Select,notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { addService } from "./api";
import { RootState } from "../../App";
import { getRetailers } from "../retailers/api";
import { clearServiceStatus, hideAddServiceModal } from "./slice";
import { slug } from "../common/utils/slug";
import { BASE_URL } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";
export default function AddServiceModal(props: any) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.services.showAddModal
  );
  const loading = useSelector(
    (state: RootState) => state.services.addServiceLoading
  );
  const error = useSelector(
    (state: RootState) => state.services.addServiceError
  );
  const {addSuccess,addError} = useSelector((state : RootState) => state.services);
 

  // const retailers = useSelector((state: RootState) => state.retailers.all);
  const retailerId = useSelector(
    (state: RootState) => state.retailers.currentRetailer?.id
  );
  const submitForm = (values: any) => {
    dispatch(addService(values));
  };

useEffect(()=>{
  if(addSuccess){
    notification.success({
      message: 'Success Message',
      description: "New service created successfully."
    })
    dispatch(clearServiceStatus())
  }
  if(addError){
    notification.error({
      message: 'Error Message',
      description: "Unable to create new service."
    })
    dispatch(clearServiceStatus())
  }
},[addSuccess,addError])

  useEffect(() => {
    dispatch(getRetailers({ retailerId: retailerId }));
  }, [])

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        if (values.notes == undefined) {
          values.notes = "";
        }
        submitForm(values);
      })
      .catch((e) => { });
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(hideAddServiceModal());
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Service"
      visible={visible}
      okText="SAVE SERVICE"
      onOk={handleModalOk}
      cancelText="CANCEL"
      onCancel={handleCancel}
      okButtonProps={loading ? { loading } : undefined}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        // name="addRetailer"
        initialValues={{ name: "" }}
        onValuesChange={handleFormChange}
      >

        <Form.Item
          label="Service name"
          name="name"
          rules={[{ required: true, message: "Service name is required!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Unique ID is required!" },
            {
              validator: async (_, value) => {

                let regex = new RegExp("^[a-zA-Z0-9_]*$");
                if(!regex.test(value)){
                  return Promise.reject("Space and special characters is not allowed.[underscore (_) is allowed.]")
                }

                const result = await fetch(`${BASE_URL}api/GetServiceUniqueID?uniqueID=${value}`,createAuthConfig());
                const data = await result.json();
                if (data.message !== "UniqueID already exists") {
                  return Promise.resolve();
                }
                else {
                  return Promise.reject(data.message);
                }
              }
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Notes (Optional)" name="notes">
          <Input.TextArea rows={6} />
        </Form.Item>

      </Form>
    </Modal>
  );
}
