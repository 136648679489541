import { CloseCircleOutlined as CloseOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Popover, Row, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { BASE_URL } from "../../ApiClient";
import { RootState } from "../../App";
import { getCategories } from "../categories/api";
import { Category } from "../categories/models";
import { getCirculars } from "../circulars/api";
import {
  Circular,
  CircularArea,
  CircularPage,
  Thumbnail,
} from "../circulars/models";
import { ConfirmDeleteModal } from "../common/components/ConfirmDeleteModal";
import { DualRingLoader } from "../common/components/Loader";
import { Actions } from "../Reducers";
import { deleteCircularArea, getPageDetails, saveArea } from "./api";
import { AreaForm } from "./AreaForm";
import { CloneModal } from "./CloneModal";
import { Container, HeaderContainer } from "./layout";
import { mapPercentage } from "./slice";

const Header = styled.div`
  width: 100%;
  border-bottom: #000c17;
  border-bottom-width: 1px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
`;

const AreaFormContainer = (props: {
  onDelete: (id: string) => void;
  zone: CircularArea;
  onClick: () => void;
  page?: CircularPage;
  onThumbnailClick: (pageId: string, index: number) => void;
  categories: Category[];
}) => {
  const dispatch = useDispatch();
  const inEditArea = useSelector(
    (state: RootState) => state.editPage.inEditArea
  );
  const newArea = useSelector((state: RootState) => state.editPage.newArea);
  const dimensions = useSelector(
    (state: RootState) => state.editPage.dimensions
  );
  const [values, setValues] = useState<any>();

    const [featured, setFeaturedChecked] = useState(false);

  const onFeaturedChange = (feature: boolean) => {
    setFeaturedChecked(feature);

  };

  useEffect(() => {}, [values]);
  const saveLoading = useSelector(
    (state: RootState) => state.editPage.saveLoading
  );

  const areaFormValues = useSelector(
    (state: RootState) => state.editPage.editFormValues
  );
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  return (
    <div style={{ minWidth: 420, maxWidth: 420 }}>
      <Header>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography.Text style={{ fontSize: 18 }}>
            {"Edit Area"}
          </Typography.Text>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CloseOutlined
            style={{ fontSize: "2em" }}
            onClick={() => {
              dispatch(Actions.editPage.cancelEditRegion());
            }}
          />
        </div>
      </Header>
      <Row style={{ position: "sticky", top: "20px" }}>
        <Col span={24}>
          <AreaForm
            categories={props.categories}
            thumbnails={props.page?.thumbnails}
            initialValues={{ ...props.zone, ...areaFormValues }}
            onValuesChange={(values) => {
              setValues(values);
              dispatch(Actions.editPage.setAreaFormValues(values));
            }}
            onFeaturedChange={onFeaturedChange}
            featured={featured}
            
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <ConfirmDeleteModal
              title={"Delete Area"}
              visible={deleteModalVisible}
              onCancel={() => {
                setDeleteModalVisible(false);
              }}
              onConfirm={() => {
                setDeleteModalVisible(false);
                props.onDelete(props.zone.id);
              }}
              label={"Are you sure you want to delete the selected area?"}
            />
            <Button
              type="danger"
              disabled={newArea}
              onClick={() => {
                setDeleteModalVisible(true);
              }}
            >
              {"Delete"}
            </Button>
            <Button
              loading={saveLoading}
              style={{ marginLeft: "16px" }}
              type="primary"
              onClick={() => {
                if (inEditArea!.top > 1) {
                  const area = {
                    top: mapPercentage(inEditArea!.top, 0, dimensions!.height),
                    left: mapPercentage(inEditArea!.left, 0, dimensions!.width),
                    width: mapPercentage(
                      inEditArea!.width,
                      0,
                      dimensions!.width
                    ),
                    height: mapPercentage(
                      inEditArea!.height,
                      0,
                      dimensions!.height
                    ),
                  };
                  const theArea = {
                    ...values,
                    ...area,
                  };
                  dispatch(
                    saveArea({
                      pageId: props.page?.id ?? "",
                      area: theArea as CircularArea,
                    })
                  );
                  return;
                }
                dispatch(
                  saveArea({
                    pageId: props.page?.id ?? "",
                    area: {
                      featured: featured ,
                      ...inEditArea,
                      ...values,
                    },
                  })
                );
              }}
            >
              {"Save"}
            </Button>
          </div>
        </Col>
        {props.zone.thumbnailIndex !== undefined ? (
          <Col span={8}>
            <div
              onClick={() =>
                dispatch(Actions.editPage.setThumbnailSelectOpened(true))
              }
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 16,
              }}
            >
              <img
                alt=""
                style={{ maxWidth: "100%" }}
                src={`${BASE_URL}api/CircularPages/${props.page?.id}/ThumbnailImage/${props.zone?.thumbnailIndex}`}
              />
            </div>
          </Col>
        ) : (
          <Button
            type={"primary"}
            onClick={() =>
              dispatch(Actions.editPage.setThumbnailSelectOpened(true))
            }
          >
            {"Add thumbnail"}
          </Button>
        )}
      </Row>
    </div>
  );
};

export const AreaEditContainer = () => {
  const history = useHistory();

  const page = useSelector((state: RootState) => state.editPage.page);
  const dispatch = useDispatch();
  const circulars: Circular[] = useSelector(
    (state: RootState) => state.circulars.all
  );
  const nav = useHistory();
  const loc = useLocation();
  const [nextPage, setNextPage] = useState<string | undefined>(undefined);
  const [prevPage, setPrevPage] = useState<string | undefined>(undefined);
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );
  console.log("retailerId form admin ap-====",retailerId)
  const { id: circularId, pageId: currentPageId } = useParams<{
    id: string;
    pageId: string;
  }>();

  useEffect(() => {
    if (!circulars.length) {
      dispatch(getCirculars({ retailerId: retailerId }));
    } else {
      const currentCircular = circulars.find((i) => i.id === circularId);
      const pageIndex = currentCircular?.circularPages.findIndex(
        (i) => i.id === currentPageId
      );
      if (pageIndex === undefined) {
        alert("error: page not found");
        return;
      }
      if (pageIndex > 0) {
        setPrevPage(currentCircular?.circularPages[pageIndex - 1].id);
      } else {
        setPrevPage(undefined);
      }

      if (pageIndex < currentCircular?.circularPages?.length! - 1) {
        setNextPage(currentCircular?.circularPages[pageIndex + 1].id);
      } else {
        setNextPage(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circulars, circularId, currentPageId]);

  useEffect(() => {
    currentPageId && dispatch(getPageDetails(currentPageId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circularId, currentPageId]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const showThumbnais = useSelector(
    (state: RootState) => state.editPage.showThumbnails
  );

  const [cloneModalVisible, setCloneModalVisible] = useState(false);

  const handleHideCloneModal = () => {
    setCloneModalVisible(false);
  };

  return (
    <Container>
      <HeaderContainer>
        <h1>Edit page</h1>
      </HeaderContainer>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Checkbox
            checked={showThumbnais}
            onChange={() => {
              dispatch(Actions.editPage.toggleShowThumbnails());
            }}
          >
            {"Show suggested areas"}
          </Checkbox>
          <Button
            type="primary"
            onClick={() => {
              setCloneModalVisible(!cloneModalVisible);
            }}
          >
            {"Clone Circular Page"}
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "baseline",
          }}
        >
          <Button
            onClick={() => {
              const path = loc.pathname.split("/");
              path.pop();
              path.pop();
              nav.push(path.join("/"));
            }}
            type={"link"}
            style={{ marginLeft: "2em" }}
          >
            {"Back"}
          </Button>

          <Button
            disabled={prevPage === undefined}
            type={"primary"}
            style={{ marginLeft: "2em" }}
            onClick={() => {
              if (prevPage === undefined) return;
              history.push(prevPage);
            }}
          >
            {"< Previous Page"}
          </Button>
          <Button
            disabled={nextPage === undefined}
            type={"primary"}
            style={{ marginLeft: "2em" }}
            onClick={() => {
              if (nextPage === undefined) return;

              history.push(nextPage);
            }}
          >
            {"Next Page >"}
          </Button>
        </div>
      </div>
      <CloneModal
        visible={cloneModalVisible}
        onCancel={handleHideCloneModal}
        circularId={circularId}
        circularPage={page}
      />
      {page && (
        <Row>
          <AreaEditor
            pageId={page.id}
            onZoneSelected={(zone: CircularArea) => {
              dispatch(Actions.editPage.editArea(zone));
            }}
          />
        </Row>
      )}
      {!page && <DualRingLoader />}
    </Container>
  );
};

const HoverRnd = styled(Rnd)`
  position: absolute;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: rgba(0, 0, 0, 0);
  box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer !important;
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.175);
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
  transition: 0.1s ease-in-out;
`;

const AreaEditor = (props: {
  pageId: string;
  onZoneSelected: (zone: CircularArea) => void;
}) => {
  const dispatch = useDispatch();

  const imageRef = useRef<HTMLImageElement>(null);
  const dimensions = useSelector(
    (state: RootState) => state.editPage.dimensions
  );

  const inEditArea = useSelector(
    (state: RootState) => state.editPage.inEditArea
  );
  const thumbnails = useSelector(
    (state: RootState) => state.editPage.thumbnails
  );
  const circularAreas = useSelector(
    (state: RootState) => state.editPage.circularAreas
  );
  const showThumbnails = useSelector<RootState>(
    (state: RootState) => state.editPage.showThumbnails
  );

  const inCreatingRegion = useSelector(
    (state: RootState) => state.editPage.inCreatingRegion
  );
  const categories = useSelector((state: RootState) => state.categories.data);
  const page = useSelector((state: RootState) => state.editPage.page);

  const mapAreas = (area: CircularArea, _: number) => {
    if (area.id === inEditArea?.id) {
      return <></>;
    }
    function rgba(arg0: number, arg1: number, arg2: number, arg3: number): import("csstype").Property.Background<string | number> | undefined {
      throw new Error("Function not implemented.");
    }

    return (
      dimensions &&
      area && (
        <Rnd
          enableResizing={{
            bottom: false,
            top: false,
            left: false,
            right: false,
          }}
          disableDragging
          key={area.id}
          bounds={".drag-container"}
          onMouseDown={() => {
            props.onZoneSelected(area);
          }}
          style={{
            border: "0.5px solid rgba(0,0,0,1)",
            borderRadius: "2px",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.05)",
            boxShadow: "inset 0px 0px 2px 0px rgba(0,0,0,1)",
            cursor: "pointer",
          }}
          position={{
            x: area.left < 1 ? area.left * dimensions.width : area.left,
            y: area.top < 1 ? area.top * dimensions.height : area.top,
          }}
          size={{
            width: area.width < 1 ? area.width * dimensions.width : area.width,
            height:
              area.height < 1 ? area.height * dimensions.height : area.height,
          }}
          default={{ x: 0, y: 0, width: 30, height: 30 }}
        />
      )
    );
  };
  const moving = useSelector((state: RootState) => state.editPage.moving);
  const mapThumbs = (dimensions: { width: number; height: number }) => (
    thumb: Thumbnail,
    index: number
  ) => {
    const defaultRect = {
      x: thumb.left < 0 ? 0 : thumb.left * dimensions.width,
      y: thumb.top < 0 ? 0 : thumb.top * dimensions.height,
      width: thumb.width * dimensions.width,
      height: thumb.height * dimensions.height,
    };
    const createFromThumbnail = (_: MouseEvent) => {
      dispatch(Actions.editPage.editFromThumbnail({ thumb, index }));
    };
    return (
      <>
        <HoverRnd
          key={`${thumb.x}${thumb.y}`}
          disableDragging
          enableResizing={{
            bottom: false,
            top: false,
            left: false,
            right: false,
          }}
          bounds={".drag-container"}
          onMouseDown={createFromThumbnail}
          default={defaultRect}
        />
      </>
    );
  };
  return (
    <Col span={12}>
      <CircularAreas
        isEditing={!!inEditArea}
        circularAreas={circularAreas}
        mapAreas={mapAreas}
      />
      {inCreatingRegion && (
        <Rnd
          key={"inCreating"}
          bounds={".drag-container"}
          style={{
            border: "1px solid rgba(128,192,255,1)",
            borderRadius: "4px",
            position: "absolute",
            background: "rgba(128,192,255,0.5)",
            boxShadow: "inset 0px 0px 5px 0px rgba(128,192,255,1)",
          }}
          position={{
            x: inCreatingRegion!.left! * dimensions!.width,
            y: inCreatingRegion!.top! * dimensions!.height,
          }}
          size={{
            width: inCreatingRegion!.width! * dimensions!.width,
            height: inCreatingRegion!.height! * dimensions!.height,
          }}
        />
      )}

      <div style={{ maxWidth: 565 }}>
        {dimensions &&
          thumbnails &&
          showThumbnails &&
          !inEditArea &&
          thumbnails.map(mapThumbs(dimensions))}
        {!!inEditArea && dimensions && (
          <>
            <Popover
              trigger={"click"}
              visible={!!inEditArea && !moving}
              content={
                <AreaFormContainer
                  categories={categories}
                  onClick={() => {}}
                  onDelete={() => {
                    dispatch(Actions.editPage.deleteInEditArea(inEditArea?.id));
                    dispatch(deleteCircularArea(inEditArea?.id));
                  }}
                  onThumbnailClick={() => {}}
                  page={page}
                  zone={inEditArea}
                />
              }
              placement={"rightBottom"}
            >
              <Rnd
                key={inEditArea.id}
                bounds={".drag-container"}
                onMouseDown={() => {}}
                style={{
                  border: "1px solid rgba(128,192,255,1)",
                  borderRadius: "4px",
                  position: "absolute",
                  background: "rgba(128,192,255,0.5)",
                  boxShadow: "inset 0px 0px 5px 0px rgba(128,192,255,1)",
                }}
                position={{
                  x:
                    inEditArea.left < 1
                      ? inEditArea.left * dimensions.width
                      : inEditArea.left,
                  y:
                    inEditArea.top < 1
                      ? inEditArea.top * dimensions.height
                      : inEditArea.top,
                }}
                size={{
                  width:
                    inEditArea.width < 1
                      ? inEditArea.width * dimensions.width
                      : inEditArea.width,
                  height:
                    inEditArea.height < 1
                      ? inEditArea.height * dimensions.height
                      : inEditArea.height,
                }}
                onDragStart={() => {
                  dispatch(Actions.editPage.setMoving(true));
                }}
                onResizeStart={() => {
                  dispatch(Actions.editPage.setMoving(true));
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                  dispatch(Actions.editPage.setMoving(false));

                  dispatch(
                    Actions.editPage.onResize({
                      x: position.x,
                      y: position.y,
                      width: parseInt(ref.style.width),
                      height: parseInt(ref.style.height),
                    })
                  );
                }}
                onDragStop={(e, d) => {
                  dispatch(Actions.editPage.setMoving(false));
                  dispatch(Actions.editPage.areaDragged({ x: d.x, y: d.y }));
                }}
              />
            </Popover>
          </>
        )}

        <img
          alt=""
          ref={imageRef}
          onMouseDown={(e) => {
            if (inEditArea) {
              return;
            }
            if (e.button > 0) return;
            const rect = e.currentTarget.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const offsetY = e.clientY - rect.top;
            dispatch(
              Actions.editPage.startCreatingRegion({ x: offsetX, y: offsetY })
            );
          }}
          onLoad={(event) => {
            dispatch(
              Actions.editPage.setDimensions({
                width: event.currentTarget.offsetWidth,
                height: event.currentTarget.offsetHeight,
              })
            );
          }}
          className={"drag-container"}
          style={{ height: 1500 }}
          src={`${BASE_URL}api/CircularPages/${props.pageId}/BigPageImage`}
        />
      </div>
    </Col>
  );
};

const CircularAreas = (props: {
  isEditing: boolean;
  circularAreas: CircularArea[] | undefined;
  mapAreas: (area: CircularArea, i: number) => JSX.Element | undefined;
}) => <>{props.circularAreas && props.circularAreas.map(props.mapAreas)}</>;
