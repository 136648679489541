import { createSlice } from "@reduxjs/toolkit";
import {
  getRecipesCategories,
  addRecipesCategory,
  getRecipesByCategory,
  editRecipe,
  editRecipesCategory,
  deleteRecipe,
  addRecipes,
  deleteRecipesCategory,
  getRecipeOfTheWeek,
  setRecipeOfTheWeek,
} from "./api";
import { RecipesCategory, Recipe, APIRecipe } from "./models";

export type RecipesState = {
  recipesByCategory: {
    [id: string]: Recipe[];
  };
  getRecipesByCategoryLoading: boolean;
  getRecipesByCategoryError: string | null;

  categories: RecipesCategory[];
  getLoading: boolean;
  getError: string | null;

  addCategoryLoading: boolean;
  addCategoryError: string | null;

  editCategoryLoading: boolean;
  editCategoryError: string | null;

  deleteCategoryLoading: boolean;
  deleteCategoryError: string | null;

  showAddCategoryModal: boolean;
  showAddRecipesModal: string | null;
  showEditCategoryModal: string | null;
  showDeleteCategoryModal: string | null;

  addRecipesLoading: boolean;
  addRecipesError: string | null;

  editRecipeLoading: boolean;
  editRecipeError: string | null;

  deleteRecipeLoading: boolean;
  deleteRecipeError: string | null;

  recipeOfTheWeek: Recipe | null;
  getRecipeOfTheWeekLoading: boolean;
  getRecipeOfTheWeekError: string | null;

  setRecipeOfTheWeekLoading: boolean;
  setRecipeOfTheWeekError: string | null;
};

const initialState: RecipesState = {
  recipesByCategory: {},
  getRecipesByCategoryLoading: false,
  getRecipesByCategoryError: null,

  categories: [],
  getLoading: false,
  getError: null,

  addCategoryLoading: false,
  addCategoryError: null,

  editCategoryLoading: false,
  editCategoryError: null,

  deleteCategoryLoading: false,
  deleteCategoryError: null,

  showAddCategoryModal: false,
  showAddRecipesModal: null,
  showEditCategoryModal: null,
  showDeleteCategoryModal: null,

  addRecipesLoading: false,
  addRecipesError: null,

  editRecipeLoading: false,
  editRecipeError: null,

  deleteRecipeLoading: false,
  deleteRecipeError: null,

  recipeOfTheWeek: null,
  getRecipeOfTheWeekLoading: false,
  getRecipeOfTheWeekError: null,

  setRecipeOfTheWeekLoading: false,
  setRecipeOfTheWeekError: null,
};

export const recipesSlice = createSlice({
  name: "recipes",
  initialState,
  reducers: {
    showAddCategoryModal: (state) => {
      state.showAddCategoryModal = true;
    },
    hideAddCategoryModal: (state) => {
      state.showAddCategoryModal = false;
    },
    showAddRecipesModal: (state, action) => {
      state.showAddRecipesModal = action.payload;
    },
    hideAddRecipesModal: (state) => {
      state.showAddRecipesModal = null;
    },
    showEditCategoryModal: (state, action) => {
      state.showEditCategoryModal = action.payload;
    },
    hideEditCategoryModal: (state) => {
      state.showEditCategoryModal = null;
    },
    showDeleteCategoryModal: (state, action) => {
      state.showDeleteCategoryModal = action.payload;
    },
    hideDeleteCategoryModal: (state) => {
      state.showDeleteCategoryModal = null;
    },
    // clearCategoryState: (state, action) => {
    //   state.recipesByCategory[action.payload] = [];
    // },
  },
  extraReducers: (builder) => {
    // get categories
    builder.addCase(getRecipesCategories.pending, (state) => {
      state.getLoading = true;
      state.getError = null;
    });
    builder.addCase(getRecipesCategories.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.getError = action.payload;
        state.getLoading = false;
      }
    });
    builder.addCase(getRecipesCategories.fulfilled, (state, action) => {
      state.getLoading = false;
      state.getError = null;
      state.categories = action.payload;
    });

    // add category
    builder.addCase(addRecipesCategory.pending, (state) => {
      state.addCategoryLoading = true;
      state.addCategoryError = null;
    });
    builder.addCase(addRecipesCategory.rejected, (state, action) => {
      state.addCategoryLoading = false;
      if (typeof action.payload === "string") {
        state.addCategoryError = action.payload;
      }
    });
    builder.addCase(addRecipesCategory.fulfilled, (state, action) => {
      state.addCategoryLoading = false;
      state.addCategoryError = null;
      state.showAddCategoryModal = false;
      state.categories = [...state.categories, action.payload];
    });

    //edit category
    builder.addCase(editRecipesCategory.pending, (state) => {
      state.editCategoryLoading = true;
      state.editCategoryError = null;
    });
    builder.addCase(editRecipesCategory.rejected, (state, action) => {
      state.editCategoryLoading = false;
      if (typeof action.payload === "string") {
        state.editCategoryError = action.payload;
      }
    });
    builder.addCase(editRecipesCategory.fulfilled, (state, action) => {
      state.editCategoryLoading = false;
      state.editCategoryError = null;
    });

    //delete category
    builder.addCase(deleteRecipesCategory.pending, (state) => {
      state.deleteCategoryLoading = true;
      state.deleteCategoryError = null;
    });
    builder.addCase(deleteRecipesCategory.rejected, (state, action) => {
      state.deleteCategoryLoading = false;
      state.showDeleteCategoryModal = null;
      if (typeof action.payload === "string") {
        state.deleteCategoryError = action.payload;
      }
    });
    builder.addCase(deleteRecipesCategory.fulfilled, (state) => {
      state.deleteCategoryLoading = false;
      state.deleteCategoryError = null;
    });

    //get recipes by category
    builder.addCase(getRecipesByCategory.pending, (state) => {
      state.getRecipesByCategoryLoading = true;
      state.getRecipesByCategoryError = null;
    });
    builder.addCase(getRecipesByCategory.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.getRecipesByCategoryError = action.payload;
      }
      state.getRecipesByCategoryLoading = false;
    });
    builder.addCase(getRecipesByCategory.fulfilled, (state, action) => {
      state.getRecipesByCategoryError = null;

      if (action.payload.length) {
        state.recipesByCategory[
          action.payload[0].recipeCategory.id as string
        ] = action.payload.map((apiRec: APIRecipe) => {
          const parsed = JSON.parse(apiRec.json);
          return {
            ...parsed,
            originalId: parsed.id,
            ...apiRec,
          };
        });
      }

      state.getRecipesByCategoryLoading = false;
    });

    //add recipes
    builder.addCase(addRecipes.pending, (state) => {
      state.addRecipesLoading = true;
      state.addRecipesError = null;
    });
    builder.addCase(addRecipes.rejected, (state, action) => {
      state.addRecipesLoading = false;
      if (typeof action.payload === "string") {
        state.addRecipesError = action.payload;
      }
    });
    builder.addCase(addRecipes.fulfilled, (state, acion) => {
      state.addRecipesLoading = false;
      state.addRecipesError = null;
    });

    //edit recipe (set them main or featured)
    builder.addCase(editRecipe.pending, (state) => {
      state.editRecipeLoading = true;
      state.editRecipeError = null;
    });
    builder.addCase(editRecipe.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editRecipeError = action.payload;
      }
      state.editRecipeLoading = false;
    });
    builder.addCase(editRecipe.fulfilled, (state, action) => {
      state.editRecipeLoading = false;
      state.editRecipeError = null;
    });

    //delete recipe
    builder.addCase(deleteRecipe.pending, (state) => {
      state.deleteRecipeLoading = true;
      state.deleteRecipeError = null;
    });
    builder.addCase(deleteRecipe.rejected, (state, action) => {
      state.deleteRecipeLoading = false;

      if (typeof action.payload === "string") {
        state.deleteRecipeError = action.payload;
      }
    });
    builder.addCase(deleteRecipe.fulfilled, (state, action) => {
      state.deleteRecipeError = null;
      state.deleteRecipeLoading = false;
    });

    //get recipe of the week
    builder.addCase(getRecipeOfTheWeek.pending, (state) => {
      state.getRecipeOfTheWeekLoading = true;
      state.getRecipeOfTheWeekError = null;
    });
    builder.addCase(getRecipeOfTheWeek.rejected, (state, action) => {
      state.getRecipeOfTheWeekLoading = false;
      if (typeof action.payload === "string") {
        state.getRecipeOfTheWeekError = action.payload;
      }
    });

    builder.addCase(getRecipeOfTheWeek.fulfilled, (state, action) => {
      state.getRecipeOfTheWeekLoading = false;
      state.getRecipeOfTheWeekError = null;
      if (action.payload !== null) {
        const parsed = JSON.parse(action.payload.json);
        state.recipeOfTheWeek = {
          ...parsed,
          originalId: parsed.id,
          ...action.payload,
        };
      } else {
        state.recipeOfTheWeek = action.payload;
      }
    });

    //set recipe of the week
    builder.addCase(setRecipeOfTheWeek.pending, (state) => {
      state.setRecipeOfTheWeekLoading = true;
      state.setRecipeOfTheWeekError = null;
    });
    builder.addCase(setRecipeOfTheWeek.rejected, (state, action) => {
      state.setRecipeOfTheWeekLoading = false;
      if (typeof action.payload === "string") {
        state.setRecipeOfTheWeekError = action.payload;
      }
    });
    builder.addCase(setRecipeOfTheWeek.fulfilled, (state, action) => {
      state.setRecipeOfTheWeekLoading = false;
      state.setRecipeOfTheWeekError = null;
      const parsed = JSON.parse(action.payload.json);
      state.recipeOfTheWeek = {
        ...parsed,
        originalId: parsed.id,
        ...action.payload,
      };
    });
  },
});

export const {
  showAddCategoryModal,
  hideAddCategoryModal,
  showEditCategoryModal,
  showAddRecipesModal,
  hideAddRecipesModal,
  hideEditCategoryModal,
  showDeleteCategoryModal,
  hideDeleteCategoryModal,
  // clearCategoryState,
} = recipesSlice.actions;
