import React, { useState } from "react";
import { Modal, Form, Alert, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import { EditCurrentUserForm } from "./EditCurrentUserForm";
import { apiClient } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";
import { getUsers } from "../users/api";

export const EditCurrentUserModal = (props: {
  visible: boolean;
  onCancel: () => void;
}) => {
  const { visible, onCancel } = props;

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      await apiClient.post("/api/Account", values, createAuthConfig());
      setSuccess(true);
      setLoading(false);
      setError("");
      handleCancel();
      dispatch(getUsers({ retailerId }));
    } catch (e) {
      setError(
        [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
      );
      setLoading(false);
    }
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      title={"My Profile"}
      visible={visible}
      confirmLoading={loading}
      onCancel={handleCancel}
      okText={"Confirm"}
      onOk={handleModalOk}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <EditCurrentUserForm form={form} onSubmit={handleSubmit} />
    </Modal>
  );
};
