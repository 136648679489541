import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col } from "antd";
import { CircularPage } from "../models";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../../ApiClient";
import { createAuthConfig } from "../../auth/api";
import { getCircularGroups } from "../../circular-groups/api";
import { getCirculars, getCircularsByid } from "../api";
import { ConfirmDeleteModal } from "../../common/components/ConfirmDeleteModal";
import { Thumbnail } from "../components/Thumbnail";
import { RootState } from "../../../App";

export const CircularPages = (props: { pages: CircularPage[] }) => {
  const [showDeleteModal, setShowDeleteModal] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  let { id: circularId } = useParams<{ id: string }>();
  const handleEditClick = (cp: CircularPage) => {
    history.push(`edit/page/${cp.id}`);
  };

  const handleDeleteClick = (id: string) => {
    setShowDeleteModal(id);
  };

  const handleModalCancel = () => {
    setShowDeleteModal("");
  };

  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );
  const deleteCircularPage = async (id: string) => {
    try {
      setIsLoading(true);
      await apiClient.delete(`api/CircularPages/${id}`, createAuthConfig());
      setIsLoading(false);
      dispatch(getCircularsByid({ id: circularId }));
      // dispatch(getCirculars({ retailerId: retailerId }));
      // dispatch(getCircularGroups({ retailerId: retailerId }));
    } catch (e) { }
  };

  return (
    <Row gutter={32} style={{ marginLeft: 32, width: "100%" }}>
      {props.pages.map((p, i) => (
        <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={6} key={p.id}>
          <div style={{ marginBottom: 16 }}>
            <Thumbnail
              key={p.id}
              circularPage={p}
              actions={[
                {
                  name: "Edit",
                  callback: handleEditClick,
                },
                {
                  name: "Delete",
                  callback: () => handleDeleteClick(p.id),
                },
              ]}
            />
            <ConfirmDeleteModal
              title={"Delete Circular Page"}
              visible={showDeleteModal === p.id}
              onCancel={handleModalCancel}
              onConfirm={() => deleteCircularPage(p.id)}
              label={`Are you sure you want to delete ${p.pageImageFileName}?`}
              isLoading={isLoading}
              
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};
