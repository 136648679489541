import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Retailer } from "../retailers/models";

export type SelectRetailerState = {
  currentRetailer?: Retailer;
  retailerSelectable: boolean;
};

const initialState: SelectRetailerState = {
  currentRetailer: undefined,
  retailerSelectable: false,
};

export const currentRetailerSlice = createSlice({
  name: "currentRetailer",
  initialState,
  reducers: {
    setCurrentRetailer: (
      state,
      action: PayloadAction<Retailer | undefined>
    ) => {
      state.currentRetailer = action.payload;
    },
    setRetailerSelectable: (state, action: PayloadAction<boolean>) => {
      state.retailerSelectable = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
