import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Checkbox, Spin, Divider, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { RecipesCategory, Recipe } from "./models";
import { hideAddRecipesModal } from "./slice";
import { RootState } from "../../App";
import { searchRecipes } from "./recipesApi";
import { getRecipesByCategory, addRecipes } from "./api";
import { RecipePreviewModal } from "./RecipePreviewModal";

const { Search } = Input;

interface AddRecipesModalProps {
  category: RecipesCategory;
}
export const AddRecipesModal: React.FC<AddRecipesModalProps> = ({
  category,
}) => {
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.recipes.showAddRecipesModal === category.id
  );
  const addError = useSelector(
    (state: RootState) => state.recipes.addRecipesError
  );

  const recipesInCategory = useSelector(
    (state: RootState) =>
      state.recipes.recipesByCategory[category.id as string] ?? []
  );

  const [selectedRec, setSelectedRec] = useState<number>(0);

  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [query, setQuery] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [findError, setFindError] = useState("");
  const [previewVisible, setPreviewVisible] = useState<boolean>(false);
  const [previewData, setPreviewData] = useState<any | null>(null);

  const addRecipesLoading = useSelector(
    (state: RootState) => state.recipes.addRecipesLoading
  );

  const getRecipesByCategoryLoading = useSelector(
    (state: RootState) => state.recipes.getRecipesByCategoryLoading
  );

  useEffect(() => {
    if (visible) {
      dispatch(getRecipesByCategory(category.id as string));
    }

    if (!visible) {
      setQuery("");
      setRecipes([]);
    }
  }, [dispatch, category.id, visible]);

  const handleCancel = () => {
    setSelectedRec(0)
    dispatch(hideAddRecipesModal());
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    setRecipes([]);
  };

  const handleSearch = async () => {
  
    if (!!query) {
      setSearchLoading(true);
      try {
      
        const results = await searchRecipes(query);
      
        setRecipes(
          results.map((result: any) => ({ ...result, selected: false }))
        );


        setSearchLoading(false);
      } catch (err) {
        // console.error("Error finding recipes:", err); // Add this line
        setFindError("Error finding recipes");
        setSearchLoading(false);
      }
    }
  };

  const handleLoadMore = async () => {
    if (!!query) {
      setSearchLoading(true);
      try {
        const results = await searchRecipes(query, recipes.length);
        setRecipes([
          ...recipes,
          ...results.map((recipe: any) => ({ ...recipe, selected: false })),
        ]);
        setSearchLoading(false);
      } catch (err) {
        setFindError(err);
        setSearchLoading(false);
      }
    }
  };

  const handleCheckboxClick = (e: any, id: any) => {

    if (e.target.checked == true) {
      setSelectedRec(selectedRec + 1)
    } else {
      setSelectedRec(selectedRec - 1)
    }
    setRecipes(
      recipes.map((recipe: any) =>
        recipe["tracking-id"] === id ? { ...recipe, selected: e.target.checked } : recipe
      )
    );
  };

  const handleAddRecipes = () => {
    handleCancel()
    dispatch(
      addRecipes(
        recipes
          .filter((recipe) => recipe.selected)
          .map((recipe) => ({
            recipeCategory: category,
            json: JSON.stringify(recipe),
            mainRecipe: false,
            recipeOfTheWeek: false,
          }))
      )
    );
  };

  const handlePreviewClick = (recipe: any) => {
    setPreviewVisible(true);
    setPreviewData(recipe);
  };

  const isAlreadySaved = (r: Recipe) => {
    // console.log(recipesInCategory.find(
    //   //@ts-ignore
    //   (recipe: Recipe) => recipe["tracking-id"] === r["tracking-id"]
    // ));
    return !!recipesInCategory.find(
      //@ts-ignore
      (recipe: Recipe) => recipe["tracking-id"] === r["tracking-id"]
    );

  };
  // const getAddCount = () =>
  //   recipes.filter((recipe) => recipe.selected && !isAlreadySaved(recipe))
  //     .length;




  return (
    <Modal
      centered
      maskClosable={false}
      title={`Add Recipes to ${category.name}`}
      visible={visible}
      onCancel={handleCancel}
      bodyStyle={{ height: "70vh", overflowY: "scroll" }}
      footer={
        <div>
          <Button key="cancel" onClick={handleCancel}>
            Close
          </Button>
          {recipes.length ? (
            <Button type="primary" onClick={handleAddRecipes}>
              Add {selectedRec} recipes to {category.name} category
            </Button>
          ) : null}
        </div>
      }
    >
      {addError && (
        <>
          <Alert message="Error" description={addError} type="error" showIcon />
          <Divider />
        </>
      )}
      <br />
      {findError && (
        <>
          <Alert
            message="Error"
            description={findError}
            type="error"
            showIcon
          />
          <Divider />
        </>
      )}
      <Spin
        spinning={
          searchLoading || addRecipesLoading || getRecipesByCategoryLoading
        }
      >
        <div style={{ display: "flex" }}>
          <Search
            value={query}
            onChange={handleInputChange}
            onSearch={handleSearch}
            enterButton
            placeholder="Search recipes"
          // enterButton="Search Recipes"
          />
          {/* <Button
            type="primary"
            onClick={handleSearch}
            style={{ marginLeft: 8 }}
          >
            Search Recipes
          </Button> */}
        </div>
        <div>
          {recipes.length ? (
            <div>
              {recipes.map((recipe: any) => (

                <div
                  style={{ display: "flex", marginTop: 8, marginBottom: 8 }}
                  key={recipe["tracking-id"]}
                  className={recipe["tracking-id"]}
                >
                  <img
                    // src={recipe.image}
                    src={recipe.content?.details?.images[0]?.hostedLargeUrl}
                    alt={recipe.content?.details?.displayName}
                    style={{ width: 80 }}
                  />
                  <div style={{ marginLeft: 16 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={recipe.selected || isAlreadySaved(recipe)}  //add checked by default
                        disabled={isAlreadySaved(recipe)}
                        onChange={(e) => handleCheckboxClick(e, recipe["tracking-id"])}
                      />
                      <h4 style={{ marginBottom: 0, marginLeft: 8 }}>
                        {recipe.content?.details?.name}
                      </h4>
                    </div>
                    <Button
                      type="link"
                      onClick={() => handlePreviewClick(recipe)}
                      style={{ paddingLeft: 0 }}
                    >
                      Preview
                    </Button>

                  </div>
                </div>
              ))}
              {previewData && <RecipePreviewModal
                visible={previewVisible}
                onCancel={() => {
                  setPreviewVisible(false)
                  setPreviewData(null)
                }}
                recipe={previewData}
              />}
              <Button
                type="link"
                onClick={handleLoadMore}
                style={{ paddingLeft: 0 ,display: 'none'}}
              >
                Load more
              </Button>
            </div>
          ) : null}
        </div>
      </Spin>
    </Modal>
  );
};
