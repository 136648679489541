import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";

import { Form, Modal, Alert, Divider } from "antd";
import { CircularsForm } from "./CircularForm";
import { RootState } from "../../App";
import { hideAddCircularModal } from "./slice";
import { Circular } from "./models";
import {
  addCircular,
  setAddCircularLoading,
  setAddCircularError,
} from "./slice";
import moment from "moment";

export const AddCircularModal = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const retailers = useSelector((state: RootState) => state.retailers.all);

  const visible = useSelector(
    (state: RootState) => state.circulars.showAddModal
  );

  const loading = useSelector((state: RootState) => state.circulars.addLoading);
  const error = useSelector((state: RootState) => state.circulars.addError);

  const currentRetailer = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer
  );
  const handleSubmit = async (values: any) => {
    dispatch(setAddCircularLoading(true));

    // we're doing this because redux doesn't let us dispatch unserializable stuff (i.e. File, FormData)

    const targetRetailer = values.retailer
      ? retailers.find((retailer) => retailer.id === values.retailer)
      : currentRetailer;

    const prepared: any = {
      Title: values.title,
      Name: values.name,
      Slug: values.slug,
      activeDate: values?.dateRange
        ? moment.utc(values?.dateRange[0]).toJSON()
        : null,
      terminationDate: values?.dateRange
        ? moment.utc(values?.dateRange[1]).toJSON()
        : null,
      // PdfFiles: values?.pdfFile.fileList,
      "Retailer.Id": targetRetailer?.id,
      "Retailer.Name": targetRetailer?.name,
      "Retailer.Slug": targetRetailer?.slug,
    };

    const formData = new FormData();

    Object.keys(prepared).forEach((key) => {
      formData.append(key, prepared[key]);
    });

    values?.pdfFile.fileList.forEach((file: any) => {
      formData.append("PdfFiles", file.originFileObj);
    });

    try {
      const res = await apiClient.post<Circular>(
        "api/Circulars",
        formData,
        // prepared,
        {
          headers: {
            ...createAuthConfig().headers,
            "Content-Type":
              "Multiple/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          },
        }
      );
      dispatch(addCircular(res.data));
    } catch (e) {
      dispatch(setAddCircularLoading(false));
      if (e.response && e.response.data && e.response.data.status === 400) {
        dispatch(
          setAddCircularError(
            [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
          )
        );
        return;
      }
      dispatch(setAddCircularError("Error adding circular"));
    }
  };

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onCancel = () => {
    form.resetFields();
    dispatch(hideAddCircularModal());
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit(values);
    } catch (e) {}
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Circular"
      visible={visible}
      onCancel={onCancel}
      onOk={form.submit}
      okText="Create"
      okButtonProps={loading ? { loading: true } : undefined}
      destroyOnClose
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <CircularsForm form={form} onSubmit={handleModalOk} />
    </Modal>
  );
};
