import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRetailers } from "./api";
import { Table, Alert, Avatar, Tooltip, Pagination, Select, Input } from "antd";
import ActionsRow from "./ActionsRow";
import { RootState } from "../../App";
import { getServices } from "../services/api";
import socialMediaIcon from "../../resources/social_media_service_icon.png";
import webSiteIcon from "../../resources/web_service_icon.png";
import loyalyIcon from "../../resources/loyalty_service_icon.png";
import emailIcon from "../../resources/email_service_icon.png";
import mobileApp from "../../resources/mobile_service_icon.png";


const { Column } = Table;

export default function RetailersTable() {
  const dispatch = useDispatch();
  const storedPageSize = localStorage.getItem("retailerPerPage");
  const [pageSize, setPageSize] = useState<number>(storedPageSize == null ? 25 : Number(storedPageSize));
  const retailers = useSelector((state: RootState) => state.retailers.all);

  const error = useSelector((state: RootState) => state.retailers.error);
  const retailerId = useSelector(
    (state: RootState) => state.retailers.currentRetailer?.id
  );
  const loading = useSelector(
    (state: RootState) => state.retailers.editRetailerLoading
  );
  const [replica, setReplica] = React.useState(retailers);
  const [searchType, setSearchType] = React.useState('name');

  const handleChangeSearch = (e: { target: { value: string; }; }) => {
    if (e.target.value.length > 0) {
      let filteredData = retailers.filter(data => data.name.toLowerCase().includes(e.target.value.toLowerCase()))
      setReplica(filteredData)
    } else {
      setReplica(retailers)
    }
  };


  // handle Change search type function
  const handleChangeSearchType = (value: string) => {
    setSearchType(value)
  }
  const orderStorageName = "retailer_order"
  let localOrder = localStorage.getItem(orderStorageName);
  const [order, setOrder] = useState<string | null>(localOrder);
  const [jsonOrder, setJsonOrder] = useState(JSON.parse(order !== null ? order : JSON.stringify({ order: "ascend", column: "name" })));
  const [columnAttr, setcolumnAttr] = React.useState<object>({
    sortOrder: jsonOrder?.order == "ascend" ? "ascend" : "descend",
    sortDirections: ["ascend", "descend", "ascend"]
  });

  useEffect(() => {
    setReplica(retailers)
  }, [retailers])
  useEffect(() => {
    if (order == null) {
      localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: "name" }));
      setOrder(JSON.stringify({ order: "ascend", column: "name" }))
      setJsonOrder({ order: "ascend", column: "name" });
    }
    if (order !== null) {
      setJsonOrder(JSON.parse(order));
    }
  }, [order]);

  const onHeaderCell = (columnName: string) => {
    return {
      onClick: () => {
        if (jsonOrder.order == "descend") {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: columnName }));
          setOrder(JSON.stringify({ order: "ascend", column: columnName }))
          setcolumnAttr({ ...columnAttr, sortOrder: "ascend" })
        }
        else {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "descend", column: columnName }));
          setOrder(JSON.stringify({ order: "descend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "descend" })
        }
      },
    };
  }

  useEffect(() => {
    dispatch(getRetailers({ retailerId: retailerId }));
  }, [dispatch, retailerId, loading]);

  useEffect(() => {
    dispatch(getServices({ retailerId: "" }))
  }, [])
  const services = useSelector(
    (state: RootState) => state.services.all
  );

  const servicesArray = [
    { serviceName: 'email', icon: emailIcon },
    { serviceName: 'loyalty', icon: loyalyIcon },
    { serviceName: 'mobile app', icon: mobileApp },
    { serviceName: 'social media', icon: socialMediaIcon },
    { serviceName: 'website', icon: webSiteIcon },
  ];

  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
        <Input.Search placeholder="Search here" style={{ width: 300, marginBottom: '1em', }} allowClear onChange={handleChangeSearch} />
        <p style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }}>Total Count: &nbsp; {replica.length} </p>
      </div>
      <Table
        loading={retailers.length == 0 ? true : false}
        pagination={{
          hideOnSinglePage: false,
          pageSize: pageSize,
          pageSizeOptions: ['25', '50', '100', '200', "ALL"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          onShowSizeChange: (current, size) => {
            localStorage.setItem("retailerPerPage",size.toString());
            setPageSize(size ? size : replica.length)
          }
        }}
        dataSource={replica.map((el, index) => ({ ...el, index: index + 1, key: el.id }))}
      >
        <Column
          title="SL No"
          dataIndex="index"
          key="index"
          {...(jsonOrder.column == "index" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("index")}
          sorter={(a: any, b: any) => {
            if (a.index < b.index) { return -1; }
            if (a.index > b.index) { return 1; }
            return 0;
          }}
        />
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          {...(jsonOrder.column == "name" && columnAttr)}
          sorter={(a: any, b: any) => {
            let fa = a.name.toLowerCase(),
              fb = b.name.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          }}
          onHeaderCell={(column) => onHeaderCell("name")}
        />
        <Column title="Slug" dataIndex="slug" key="slug" />
        <Column title="Services"
          dataIndex="service"
          key="service"
          render={(data) => {
            let serviceArray = data?.split(",") || [];
            return (
              <div style={{ display: "flex", columnGap: 10, justifyContent: "start" }}>
                {
                  servicesArray.map((item: any, index: number) => {
                    let serviceData = services.find(service => service.name.toLowerCase() == item.serviceName)
                    if (serviceArray.includes(serviceData?.id.toString())) {
                      return <Tooltip key={index} title={serviceData?.name} placement="top">
                        <Avatar size="small" shape="square" src={item.icon} style={{
                          backgroundColor: '#87d068',
                        }} >{serviceData?.name.substring(0, 2).toUpperCase()}</Avatar>
                      </Tooltip>;
                    }
                    else {
                      return <div key={index} style={{ width: 24, height: 24, }}></div>;
                    }
                  })
                }
                {
                  data !== null && data !== "" && serviceArray.map((item: string, index: number) => {
                    let serviceData = services.find(service => service.id == item)
                    if (!servicesArray.find(item => item.serviceName == serviceData?.name.toLowerCase())) {
                      return <Tooltip key={index} title={serviceData?.name} placement="top">
                        <Avatar size="small" shape="square" style={{
                          backgroundColor: '#87d068',
                        }} >{serviceData?.name.substring(0, 2).toUpperCase()}
                        </Avatar>
                      </Tooltip>
                    }
                  })
                }
              </div>
            )
          }} />

        <Column
          title=""
          key="actions"
          render={(row: any) => {
            return <ActionsRow key={row.id} row={row} />;
          }}
        />
      </Table>
    </>
  );
}
