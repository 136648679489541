import React, { useState } from "react";
import { Modal, Form, Input, Alert, Divider } from "antd";
import { apiClient } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";

export const ChangePasswordModal = (props: {
  visible: boolean;
  onCancel: () => void;
}) => {
  const { visible, onCancel } = props;

  const [form] = Form.useForm();

  const [, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    try {
      await apiClient.post(
        "/api/Account/ChangePassword",
        values,
        createAuthConfig()
      );
      setSuccess(true);
      setLoading(false);
      setError("");
      handleCancel();
    } catch (e) {
      setError(
        [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
      );
      setLoading(false);
    }
  };

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        handleSubmit(values);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      title={"Change password"}
      visible={visible}
      onCancel={handleCancel}
      okText={"Confirm"}
      confirmLoading={loading}
      onOk={handleModalOk}
    >
      <>
        {error && (
          <>
            <Alert message="Error" description={error} type="error" showIcon />
            <Divider />
          </>
        )}
        <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[{ required: true, message: "Old password is required!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New password"
            name="password"
            rules={[{ required: true, message: "New password is required!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Confirm password is required" },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};
