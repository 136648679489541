import { Store, FormStore } from "./models";
import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { CircularGroup } from "../circular-groups/models";

export const getStores = createAsyncThunk(
  "/api/Stores/Get",
  async (
    _params: { retailerId: string | undefined },
    thunkAPI
  ): Promise<Store[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<Store[]>(
        `api/Stores?retailerId=${_params.retailerId ? _params.retailerId : ""}`,
        createAuthConfig()
      );
      return res.data as Store[];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting stores");
    }
  }
);

export const addStore = createAsyncThunk(
  "/api/Stores/Add",
  async (
    params: FormStore,
    thunkAPI
  ): Promise<Store | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<Store>(
        "api/Stores",
        {
          ...params,
          //@ts-ignore
          circularGroup: thunkAPI
            .getState()
            .circularGroups.all.find(
              (cg: CircularGroup) => cg.id === params.circularGroupId
            ),
        },
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding store");
    }
  }
);

export const editStore = createAsyncThunk(
  "/api/Stores/Edit",
  async (
    params: Store,
    thunkAPI
  ): Promise<Store | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.put<Store>(
        `api/Stores/${params.id}`,
        {
          ...params,
          //@ts-ignore
          circularGroup: thunkAPI
            .getState()
            .circularGroups.all.find(
              (cg: CircularGroup) => cg.id === params.circularGroupId
            ),
        },
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing store");
    }
  }
);

export const deleteStore = createAsyncThunk(
  "/api/Stores/Delete",
  async (
    id: string,
    thunkAPI
  ): Promise<Store | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.delete<Store>(
        `api/Stores/${id}`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting store");
    }
  }
);
