import { LoginData, User } from "./models";
import { apiClient } from "../../ApiClient";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../../App";

//utility to create axios auth header config
export const createAuthConfig = () => {
  let authData = store.getState().login.authData;
  return {
    headers: {
      Authorization: `Bearer ${authData?.jwt}`,
    },
  };
};

//handles both the login post and the subsequent account get
export const loginUser = createAsyncThunk(
  "/Account/Login",
  async (params: { email: string; password: string }, thunkAPI) => {
    try {
      const loginResponse = await apiClient.post<LoginData>(
        "api/Account/Login",
        params
      );
      localStorage.setItem("loginData", JSON.stringify(loginResponse.data));
      thunkAPI.dispatch({
        type: "login/setLoginCreds",
        payload: loginResponse.data,
      });
      const userResponse = await apiClient.get<User>("api/Account", {
        headers: {
          Authorization: `Bearer ${loginResponse.data.jwt}`,
        },
      });
      if (userResponse.data.role === "Administrator") {
        thunkAPI.dispatch({
          type: "currentRetailer/setRetailerSelectable",
          payload: true,
        });
        thunkAPI.dispatch({
          type: "currentRetailer/setCurrentRetailer",
          payload: null,
        });
      } else {
        thunkAPI.dispatch({
          type: "currentRetailer/setRetailerSelectable",
          payload: false,
        });
        thunkAPI.dispatch({
          type: "currentRetailer/setCurrentRetailer",
          payload: userResponse.data.retailer!,
        });
      }
      localStorage.setItem("user", JSON.stringify(userResponse.data));

      return [loginResponse.data, userResponse.data];
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error logging in");
    }
  }
);
