import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStores } from "./api";
import { getRetailers } from "../retailers/api";
import { getCircularGroups } from "../circular-groups/api";
import { Table } from "antd";
import ActionsRow from "./ActionsRow";
import { RootState } from "../../App";

const { Column } = Table;

export default function StoresTable() {
  const dispatch = useDispatch();
  const stores = useSelector((state: RootState) => state.stores.all);
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );
  useEffect(() => {
    dispatch(getRetailers({ retailerId: retailerId }));
    dispatch(getCircularGroups({ retailerId: retailerId }));
    dispatch(getStores({ retailerId: retailerId }));
  }, [dispatch, retailerId]);

  return (
    <Table
      pagination={{ hideOnSinglePage: true }}
      dataSource={stores.map((el) => ({
        ...el,
        key: el.id,
        id: el.id,
        retailerName: el.circularGroup.retailer.name,
        circularGroupName: el.circularGroup.name,
      }))}
    >
      <Column title="Store Number" dataIndex="storeNumber" key="storeNumber" />
      <Column title="Store" dataIndex="name" key="name" />
      <Column title="Slug" dataIndex="slug" key="slug" />
      <Column
        title="Circular Group"
        dataIndex="circularGroupName"
        key="circularGroupName"
      />
      <Column title="Retailer" dataIndex="retailerName" key="retailerName" />
      <Column
        title=""
        key="actions"
        render={(row: any) => {
          return <ActionsRow key={row.id} row={row} />;
        }}
      />
    </Table>
  );
}
