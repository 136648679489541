import React from "react";
import { RecipesCategory } from "./models";
import { Button, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import {
  showEditCategoryModal,
  showDeleteCategoryModal,
  showAddRecipesModal,
} from "./slice";
import { EditRecipesCategoryModal } from "./EditRecipesCategoryModal";
import { DeleteRecipesCategoryModal } from "./DeleteRecipesCategoryModal";
import { AddRecipesModal } from "./AddRecipesModal";
import { editRecipesCategory } from "./api";

interface ActionsRowProps {
  row: RecipesCategory;
}

export const ActionsRow: React.FC<ActionsRowProps> = ({ row }) => {
  const dispatch = useDispatch();
  const handleEditButtonClick = () => {
    dispatch(showEditCategoryModal(row.id));
  };

  const handleDeleteButtonClick = () => {
    dispatch(showDeleteCategoryModal(row.id));
  };

  const handleAdd = () => {
    dispatch(showAddRecipesModal(row.id));
  };

  const handleFeaturedChange = () => {
    dispatch(editRecipesCategory({ ...row, featured: !row.featured }));
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Checkbox checked={row.featured} onChange={handleFeaturedChange} />
          <Button type="link" onClick={handleFeaturedChange}>
            Featured
          </Button>
        </div>
        <Button type="link" onClick={handleAdd}>
          Add Recipes
        </Button>
        <Button type="link" onClick={handleEditButtonClick}>
          Edit
        </Button>
        <Button type="link" danger onClick={handleDeleteButtonClick}>
          Delete
        </Button>
        <AddRecipesModal category={row} />
        <EditRecipesCategoryModal category={row} />
        <DeleteRecipesCategoryModal category={row} />
      </div>
    </>
  );
};
