import React, { useEffect, useState } from "react";
import { Modal, Form, Typography, Input, Button, notification } from "antd";

import { BASE_URL, apiClient } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";
import moment from "moment";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import Cookies from "js-cookie";
import { FacebookFilled } from "@ant-design/icons";
export const SettingModal = (props: {
  visible: boolean;
  onCancel: () => void;
}) => {
  const { visible, onCancel } = props;
  const [form] = Form.useForm();
  const [formFacebook] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [fecebookDetails, setFacebookDetails] = useState<{
    id: string;
    userName: string;
    photo: string;
    accessToken: string;
    name: string;
    email: string;
    userId: string;
    expairyDate: number;
    createdDate: Date;
    updatedDate: Date;
  }>();
  const [getData, setGetData] = useState<any>("");

  useEffect(() => {
    (async () => {
      const result = await fetch(BASE_URL + 'api/GetAllAppID', createAuthConfig());
      const data = await result.json();
      setGetData(data.appidDetails[0]);
      formFacebook.setFieldsValue({ app_id: data.appidDetails[0].appId, secret_key: data.appidDetails[0].appsecretId })
    })()
  }, [])




  useEffect(() => {
    if (!fecebookDetails) {
      (async () => {
        const result = await fetch(`${BASE_URL}api/GetAllSetting`, createAuthConfig());
        const data = await result.json();
        setFacebookDetails(data.settingDetails[0]);
      })()
    }
  }, [visible])

 
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const { data } = await apiClient.post("/api/PostSettingDetails", values, createAuthConfig());
      notification.success({
        message: 'Success Message',
        description: data.message
      });
      setLoading(false);
      handleCancel();
    } catch (e) {
      setLoading(false);
      notification.error({
        message: 'Success Message',
        description: "Unable to create facebook credentials"
      });
    }
  };


  const handelFacebookSubmit = async (data: any) => {
    try {
      // console.log("data", data);
      const res = await formFacebook.validateFields()
      console.log("res", res);


      setLoading(true)
      const payload = {
        "id": getData.id,
        "appId": res.app_id,
        "appsecretId": res.secret_key
      }

      const { data } = await apiClient.post("/api/EditAppIDDetails", payload, createAuthConfig())
      notification.success({
        message: 'Success Message',
        description: data.message
      })
      setLoading(false)
      handleCancel()
    } catch (e) {
      setLoading(false)
      notification.error({
        message: 'Success Message',
        description: "Unable to create "
      })
    }
  }

  const handleCancel = () => {
    onCancel();
  };
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  // const responseFacebook = (response: any) => {
  //   console.log(moment(response.data_access_expiration_time * 1000).format('DD/MM/YYYY hh:mm:ss a'));
  //   console.log(response);
  // }
  const logOut = () => {
    FacebookLoginClient.logout(() => {
      Cookies.remove('fblo_487272066091952')
      sessionStorage.removeItem("fbssls_487272066091952");
    });

    (async () => {
      await apiClient.post("/api/PostSettingDetails", {
        ...fecebookDetails,
        accessToken: ""
      }, createAuthConfig());

      const result = await fetch(`${BASE_URL}api/GetAllSetting`, createAuthConfig());
      const data = await result.json();
      setFacebookDetails(data.settingDetails[0]);
    })()
  }

  // console.log("form", formFacebook.getFieldValue());


  return (
    <Modal
      maskClosable={false}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >

      <Typography.Title level={2}>Settings</Typography.Title>
      {/* <Typography.Title level={4} style={{ marginTop: 15 }}>Facebook Account Credentials</Typography.Title>
      <Form form={formFacebook} {...layout} >
        <Form.Item label="App ID" name="app_id" rules={[{ required: true, message: "App ID field is required." }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Secret Key" name="secret_key" rules={[{ required: true, message: "Secret Key field is required." }]}>
          <Input />
        </Form.Item>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: 10 }}>
          <Button type="primary" onClick={handelFacebookSubmit}>Submit</Button>
        </div>
      </Form><br /> */}

      {
        fecebookDetails?.accessToken == null &&

        <FacebookLogin
          appId={getData.appId}
          style={{ padding: 0, margin: 0, border: "none" }}
          onSuccess={(response: any) => {

            FacebookLoginClient.getProfile(async (res: any) => {
              console.log("res", res);

              const { data } = await apiClient.post("/api/PostSettingDetails", {
                ...fecebookDetails,
                userName: res.name,
                photo: res.picture.data.url,
                accessToken: response.accessToken,
                name: res.name,
                email: res.email,
                userId: res.id,
                expairyDate: response.data_access_expiration_time
              }, createAuthConfig());

              (async () => {
                const result = await fetch(`${BASE_URL}api/GetAllSetting`, createAuthConfig());
                const data = await result.json();
                setFacebookDetails(data.settingDetails[0]);
              })()

            }, { fields: "id,name,email,picture" })

          }}
          onFail={(error) => {
            Cookies.remove('fblo_487272066091952')
            sessionStorage.removeItem("fbssls_487272066091952");
          }}

        >
          <Button type="primary" style={{ display: "flex", alignItems: "center" }} size="large" icon={<FacebookFilled style={{ fontSize: 22 }} />} >Login With Facebook</Button>
        </FacebookLogin>
      }

      {
        fecebookDetails?.accessToken !== null &&
        <Form form={form} {...layout} initialValues={fecebookDetails} onFinish={handleSubmit} >
          <Form.Item label="Name" name="name" rules={[{ required: true, message: "Username field is required." }]}>
            <Input />
          </Form.Item>
          <Form.Item label="API Key" name="accessToken" rules={[{ required: true, message: "API Key field is required." }]}>
            <Input.Password />
          </Form.Item>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "end", columnGap: 10 }}>
            <Button htmlType="button" onClick={handleCancel}> CANCEL </Button>
            <Button loading={loading} disabled={loading} onClick={logOut} type="primary" > Logout </Button>
          </div>
        </Form>
      }

    </Modal>
  );
};
