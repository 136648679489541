import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getProperties } from "./api";
import { Table, Alert, Select, Button } from "antd";
import { RootState } from "../../App";
import Search from "antd/lib/input/Search";

import { EyeOutlined } from "@ant-design/icons";
import ViewPropertyModal from "./ViewPropertyModal";
import EditPropertyModal from "./EditPropertyModal";
import DeletePropertyModal from "./DeletePropertyModal";
import EditICon from '../../resources/ICON-edit-pencil.png';
import DeleteICon from '../../resources/ICON-trash-can-bin-delete-remove.png';
import { showDeletePropertyModal, showEditPropertyModal, showViewPropertyModal } from "./slice";
import { Properties } from "./models";
const { Column } = Table;

export default function PropertiesTable() {
  const dispatch = useDispatch();
  const [editId, setEditId] = React.useState<number>();
  const [deleteId, setDeleteId] = React.useState<number>();
  const [viewId, setViewId] = React.useState<number>()
  const storedPageSize = localStorage.getItem("propertyPerPage");
  const [pageSize, setPageSize] = useState<number>(storedPageSize == null ? 25 : Number(storedPageSize));
  const properties = useSelector((state: RootState) => state.properties.all);
  const error = useSelector((state: RootState) => state.properties.error);
  const [replica, setReplica] = React.useState(properties);
  const [searchType, setSearchType] = React.useState('name');

  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );
 
  const orderStorageName = "properties_order"
  let localOrder = localStorage.getItem(orderStorageName);
  const [order, setOrder] = useState<string | null>(localOrder);
  const [jsonOrder, setJsonOrder] = useState(JSON.parse(order !== null ? order : JSON.stringify({ order: "ascend", column: "name" })));
  const [columnAttr, setcolumnAttr] = React.useState<object>({
    sortOrder: jsonOrder?.order == "ascend" ? "ascend" : "descend",
    sortDirections: ["ascend", "descend", "ascend"]
  });
  useEffect(() => {
    if (order == null) {
      localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: "name" }));
      setOrder(JSON.stringify({ order: "ascend", column: "name" }))
      setJsonOrder({ order: "ascend", column: "name" });
    }
    if (order !== null) {
      setJsonOrder(JSON.parse(order));
    }
  }, [order]);

  const onHeaderCell = (columnName: string) => {
    return {
      onClick: () => {
        if (jsonOrder.order == "descend") {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "ascend", column: columnName }));
          setOrder(JSON.stringify({ order: "ascend", column: columnName }))
          setcolumnAttr({ ...columnAttr, sortOrder: "ascend" })
        }
        else {
          localStorage.setItem(orderStorageName, JSON.stringify({ order: "descend", column: columnName }));
          setOrder(JSON.stringify({ order: "descend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "descend" })
        }
      },
    };
  }


  // Handle Change Search
  const handleChangeSearch = (e: { target: { value: string; }; }) => {
    if (e.target.value.length > 0) {
      let filteredData: Properties[] = [];
      switch (searchType) {
        case 'name':
          filteredData = properties.filter(data => data.name.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case 'retailerName':
          filteredData = properties.filter(data => data.retailerName.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case 'uniqueID':
          filteredData = properties.filter(data => data.uniqueID.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case 'serviceName':
          filteredData = properties.filter(data => data.serviceName !== null && data.serviceName.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        case 'pageID':
          filteredData = properties.filter(data => data.pageID.toLowerCase().includes(e.target.value.toLowerCase()))
          break;
        default:
          break;
      }
      setReplica(filteredData)
    } else {
      setReplica(properties)
    }
  };

  useEffect(() => {
    setReplica(properties)
  }, [properties]);

  useEffect(() => {
    dispatch(getProperties({ retailerId: retailerId }));
  }, [dispatch, retailerId]);

  const defaultOptions = [ 
    { value: 'retailerName', label: 'Retailer Name' },
    { value: 'serviceName', label: 'Service Name' },
    { value: 'name', label: 'Property Name' } 
  ]; 
  
  const [options, setOptions] = useState(defaultOptions); 
  const [selectedValue, setSelectedValue] = useState("name");
  
  useEffect(() => { 
    try { 
      // Retrieve stored options from localStorage if they exist 
      const storedOptions = localStorage.getItem("selectedPropertyType") ?? ""
      
      if (storedOptions) { 
        setSelectedValue(storedOptions); 
      } else { 
        // Save the default options to localStorage if not already stored 
        localStorage.setItem("selectOptions", JSON.stringify(defaultOptions)); 
      } 
  
      // Get the stored selected value from localStorage 
      const storedValue = localStorage.getItem("selectedPropertyType"); 
      if (storedValue) { 
        setSelectedValue(storedValue); 
      } 
    } catch (error) { 
      console.error("Error accessing localStorage:", error); 
    } 
  }, []);

  // Handle Change Search Type
  const handleChangeSearchType = (value: string) => {
    setSearchType(value);
    setSelectedValue(value);
    localStorage.setItem("selectedPropertyType", value);
  }



  // Handle View Button Click
  const handleViewButtonClick = (row: any) => {
    setViewId(row.id);
    dispatch(showViewPropertyModal(row.id));
  };

  // Handle Edit Button Click
  const handleEditButtonClick = (row: any) => {
    setEditId(row.id)
    dispatch(showEditPropertyModal(row.id));
  };
  // Handle Delete Button Click
  const handleDeleteButtonClick = (row: any) => {
    setDeleteId(row.id)
    dispatch(showDeletePropertyModal(row.id));
  };
  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>
          <Select
            style={{ width: 250 }}
            options={options}
            onChange={handleChangeSearchType}
            value={selectedValue}
          />
        </div>
        <Search placeholder="Search here" style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }} allowClear onChange={handleChangeSearch} />
        <p style={{ width: 300, marginBottom: '1em', marginLeft: '1em' }}>Total Count: &nbsp; {replica.length} </p>
      </div>
      <Table
        pagination={{
          hideOnSinglePage: false,
          pageSize: pageSize,
          pageSizeOptions: ['25', '50', '100', '200'],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          onShowSizeChange: (current, size) => {
            localStorage.setItem("propertyPerPage",size.toString());
            setPageSize(size ? size : replica.length)
          }
        }}
        dataSource={[...replica,]
          ?.reverse()
          ?.map((el, index) => ({
            ...el,
            index: index + 1,
            key: el?.id,
            retailerName: el?.retailerName == null ? "N/A" : el?.retailerName,
            serviceName: el?.serviceName == null ? "N/A" : el?.serviceName,
            pageID: el?.pageID == "" ? "N/A" : el?.pageID,
          }))}
      >
        <Column
          title="SL No"
          dataIndex="index"
          {...(jsonOrder.column == "index" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("index")}
          key="index" sorter={(a: any, b: any) => {
            if (a.index < b.index) { return -1; }
            if (a.index > b.index) { return 1; }
            return 0;
          }} />
        <Column
          title="Retailer Name"
          dataIndex="retailerName"
          key="retailerName"
          {...(jsonOrder.column == "retailerName" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("retailerName")}
          sorter={(a: any, b: any) => {
            if (a.retailerName < b.retailerName) { return -1; }
            if (a.retailerName > b.retailerName) { return 1; }
            return 0;
          }} />

        <Column
          title="Service Name"
          dataIndex="serviceName"
          key="serviceName"
          {...(jsonOrder.column == "serviceName" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("serviceName")}
          sorter={(a: any, b: any) => {
            if (a.serviceName < b.serviceName) { return -1; }
            if (a.serviceName > b.serviceName) { return 1; }
            return 0;
          }} />

        <Column
          title="Property Name"
          dataIndex="name"
          {...(jsonOrder.column == "name" && columnAttr)}
          onHeaderCell={(column) => onHeaderCell("name")}
          key="name" sorter={(a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
          }} />



        <Column
          title="View"
          key="view"
          align="center"
          render={(row: any) => {
            return (<div>
              <Button title="View"
                type="link" onClick={() => handleViewButtonClick(row)}>
                <EyeOutlined style={{ color: '#565050', height: '24px', width: '24px', margin: '0 0.8em' }} />
              </Button>
              {
                (viewId && viewId == row.id) &&
                <ViewPropertyModal row={row} />
              }
            </div>)
          }}
        />

        <Column
          title="Edit"
          key="edit"
          align="center"
          render={(row: any) => {
            return (<div>
              <Button title="Edit" type="link" onClick={() => handleEditButtonClick(row)}>
                <img src={EditICon} alt="Edit Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
              </Button>
              {
                (editId && editId == row.id) &&
                <EditPropertyModal row={row} />
              }
            </div>)
          }}
        />

        <Column
          title="Delete"
          key="delete"
          align="center"
          render={(row: any) => {
            return (<div>
              <Button
                title="Delete"
                type="link" danger onClick={() => handleDeleteButtonClick(row)}>
                <img src={DeleteICon} alt="Edit Icon" style={{ height: '24px', width: '24px', margin: '0 0.8em' }} />
              </Button>
              {
                (deleteId && deleteId == row.id) &&
                <DeletePropertyModal row={row} />
              }
            </div>)
          }}
        />
      </Table>


    </>
  );
}
