import React, { useEffect, useState } from "react";
import { Button, Input, Table, message } from "antd";
import { ConfirmDeleteModal } from "../common/components/ConfirmDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import {
  deleteCategory,
  getCategories,
  postCategory,
  putCategory,
} from "./api";
import {
  CategoriesActions,
  CategoryRow,
  Container,
  EditableCell,
  HeaderContainer,
} from "./components";
import { Actions } from "../Reducers";

const { Column } = Table;

export const Categories = () => {
  const dispatch = useDispatch();
  const rawData = useSelector((state: RootState) => state.categories.data);

  const updateLoading = useSelector(
    (state: RootState) => state.categories.updateLoading
  );

  const deleteDirty = useSelector(
    (state: RootState) => state.categories.deleteDirty
  );
  const addDirty = useSelector((state: RootState) => state.categories.addDirty);
  const updateDirty = useSelector(
    (state: RootState) => state.categories.updateDirty
  );

  const error = useSelector((state: RootState) => state.categories.error);

  const [inputValue, setInputValue] = useState("");
  const [showDeleteModal, setDeleteModal] = useState({
    visible: false,
    index: -1,
  });
  const [tableData, setTableData] = useState([] as CategoryRow[]);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  useEffect(() => {
    setTableData(
      rawData.map((v, i) => ({
        id: v.id,
        index: i + 1,
        name: v.name,
        key: v.id,
      }))
    );
  }, [rawData]);

  useEffect(() => {
    addDirty && message.success("Category added!");
    dispatch(Actions.categories.addInfoShowed());
  }, [dispatch, addDirty]);
  useEffect(() => {
    updateDirty && message.success("Category updated!");
    dispatch(Actions.categories.updateInfoShowed());
  }, [dispatch, updateDirty]);
  useEffect(() => {
    deleteDirty && message.success("Category deleted!");
    dispatch(Actions.categories.deleteInfoShowed());
  }, [dispatch, deleteDirty]);

  useEffect(() => {
    error.dirty && message.error(error.message);
    dispatch(Actions.categories.errorShowed);
  }, [dispatch, error.dirty, error.message]);

  return (
    <Container>
      <ConfirmDeleteModal
        title={"Delete Category"}
        visible={showDeleteModal.visible}
        onCancel={() => {
          setDeleteModal({ visible: false, index: -1 });
        }}
        onConfirm={() => {
          dispatch(
            deleteCategory({ id: tableData[showDeleteModal.index - 1].id })
          );
          setDeleteModal({ visible: false, index: -1 });
        }}
        label={"Are you sure you want to delete this?"}
      />
      <h1>Categories</h1>
      <HeaderContainer>
        <Input
          placeholder="Category Name"
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          value={inputValue}
        />
        <Button
          type="primary"
          onClick={() => {
            dispatch(postCategory({ name: inputValue }));
            setInputValue("");
          }}
          style={{ marginLeft: 16 }}
        >
          Create
        </Button>
      </HeaderContainer>
      <Table
        dataSource={tableData}
        pagination={{ pageSize: 50, hideOnSinglePage: true }}
      >
        <Column
          title={"Name"}
          key={"name"}
          render={(row) => {
            return (
              <EditableCell
                onUpdate={(row) => {
                  dispatch(putCategory(row));
                }}
                row={row}
                isUpdating={
                  updateLoading.id === row.id && updateLoading.loading
                }
                key={row.id}
              />
            );
          }}
        />
        <Column
          title={""}
          key={"actions"}
          render={(row) => {
            return (
              <CategoriesActions
                onDelete={() => {
                  setDeleteModal({ visible: true, index: row.index });
                }}
                row={row}
              />
            );
          }}
        />
      </Table>
    </Container>
  );
};
