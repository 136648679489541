import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "../404";
import { RootState } from "../App";
import { AreaEditContainer } from "./areas/AreaEdit";
import { Categories } from "./categories";
import CircularGroups from "./circular-groups";
import Circulars from "./circulars";
import CircularEdit from "./circulars/EditPage";
import EmailsStatistics from "./emails/index";
import { Recipes } from "./recipes";
import Retailers from "./retailers";
import Stores from "./stores";
import Users from "./users";
import Connections from './connections';
import Services from './services';
import Subservices from './subservices';
import Properties from './properties';
import ActivityGroups from "./activitylog";

export const Routes = () => {
  const match = useRouteMatch();
  const isAdmin =
    useSelector((Store: RootState) =>
      Store.auth.authData?.claims.Permission.find(
        (permission) => permission === "IsAdministrator"
      )
    ) !== undefined;

  return (
    <Switch>
      {/*TODO: route based on roles  */}
      <Route
        exact
        path={`${match.url}/`}
        component={isAdmin ? Retailers : Users}
      />
      {isAdmin ? (
        <Route exact path={`${match.url}/retailers`} component={Retailers} />
      ) : null}
      {isAdmin ? (
        <Route exact path={`${match.url}/categories`} component={Categories} />
      ) : null}
      <Route
        exact
        path={`${match.url}/circular-groups`}
        component={CircularGroups}
      />
      <Route exact path={`${match.url}/users`} component={Users} />
      <Route exact path={`${match.url}/circulars`} component={Circulars} />
      <Route exact path={`${match.url}/emails`} component={EmailsStatistics} />
      <Route exact path={`${match.url}/connections`} component={Connections} />
      <Route exact path={`${match.url}/services`} component={Services} />
      <Route exact path={`${match.url}/subservices`} component={Subservices} />
      <Route exact path={`${match.url}/properties`} component={Properties} />
      <Route exact path={`${match.url}/activitylog`} component={ActivityGroups} />
      <Route
        exact
        path={`${match.url}/circulars/:id/edit`}
        component={CircularEdit}
      />
      <Route
        exact
        path={`${match.url}/circulars/:id/edit/page/:pageId`}
        component={AreaEditContainer}
      />

      <Route exact path={`${match.url}/stores`} component={Stores} />
      {isAdmin ? (
        <Route exact path={`${match.url}/recipes`} component={Recipes} />
      ) : null}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};
