import { createAsyncThunk } from "@reduxjs/toolkit";
import { createAuthConfig } from "../auth/api";
import { apiClient } from "../../ApiClient";
import { Category } from "./models";

export const getCategories = createAsyncThunk(
  "getCategories",
  async (
    params,
    thunkAPI
  ): Promise<Category[] | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.get<Category[]>(
        `api/Categories`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error getting categories");
    }
  }
);

export const postCategory = createAsyncThunk(
  "postCategory",
  async (
    params: { name: string },
    thunkAPI
  ): Promise<Category | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.post<Category>(
        `api/Categories`,
        params,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error adding category");
    }
  }
);

export const putCategory = createAsyncThunk(
  "putCategory",
  async (
    params: { id: string; name: string },
    thunkAPI
  ): Promise<Category | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.put<Category>(
        `api/Categories/${params.id}`,
        params,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error editing category");
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "deleteCategory",
  async (
    params: { id: string },
    thunkAPI
  ): Promise<Category | ReturnType<typeof thunkAPI.rejectWithValue>> => {
    try {
      const res = await apiClient.delete<Category>(
        `api/Categories/${params.id}`,
        createAuthConfig()
      );
      return res.data;
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        return thunkAPI.rejectWithValue(
          [].concat.apply([], Object.values(e.response.data.errors)).join(" ")
        );
      }
      return thunkAPI.rejectWithValue("Error deleting category");
    }
  }
);
