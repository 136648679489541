import { slug } from "../common/utils/slug";

export const formatAddressAndSlug = (res: any, coordinates: any) => {
  let address: any = {
    streetNumber: "",
    street: "",
    city: "",
    county: "",
    state: "",
    zipCode: "",
    address1: "",
    address2: "",
    latitude: "",
    longitude: "",
    slug: "",
  };

  let emptyAddress = { ...address };

  let isUS = true;

  res.address_components.forEach((component: any) => {
    if (component.types.includes("street_number")) {
      address = { ...address, streetNumber: component.long_name ?? "" };
    }
    if (component.types.includes("route")) {
      address = { ...address, street: component.long_name ?? "" };
    }
    if (
      component.types.includes("locality") &&
      component.types.includes("political")
    ) {
      address = { ...address, city: component.long_name ?? "" };
    }

    if (
      component.types.includes("administrative_area_level_2") &&
      component.types.includes("political")
    ) {
      address = { ...address, county: component.long_name ?? "" };
    }

    if (
      component.types.includes("administrative_area_level_1") &&
      component.types.includes("political")
    ) {
      address = {
        ...address,
        state: component.short_name ?? "",
        stateLong: component.long_name ?? "",
      };
    }

    if (component.types.includes("postal_code")) {
      address = { ...address, zipCode: component.long_name ?? "" };
    }

    if (
      component.types.includes("country") &&
      component.types.includes("political") &&
      component.short_name !== "US"
    ) {
      isUS = false;
      return;
    }
  });

  return isUS
    ? {
        ...address,
        address1: `${address.streetNumber ?? ""}, ${address.street ?? ""}`,
        address2: `${address.city ?? ""}, ${address.county ?? ""}, ${
          address.state ?? ""
        }`,
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        slug: slug(`${address.city} ${address.stateLong}`),
      }
    : emptyAddress;
};
