import { Alert, Divider, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../App";

export const EmailsTable = () => {
  useEffect(() => {}, []);

  const dataSource = useSelector((Store: RootState) =>
    Store.emails.emailsStatistics.map((statistic, position) => {
      return { ...statistic, key: position };
    })
  );

  const statusGetReport = useSelector(
    (Store: RootState) => Store.emails.statusGetEmailsReport
  );

  const tableColumns: ColumnsType<any> = [
    {
      title: "Retailer",
      dataIndex: ["retailer", "name"],
    },

    { title: "Emails", dataIndex: "count" },
  ];

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={dataSource}
        pagination={{ hideOnSinglePage: true }}
      />
      {statusGetReport.progress === "error" ? (
        <>
          <Alert
            message="Error"
            description={statusGetReport.message}
            type="error"
            showIcon
          />
          <Divider />
        </>
      ) : null}
    </>
  );
};
