import { DatePicker, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../App";
import { emailsSlice } from "./slice";
const { setDateInterval } = emailsSlice.actions;

const Container = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const { RangePicker } = DatePicker;
export const SelectDateInterval = () => {
  const dispatch = useDispatch();

  const { dateInterval } = useSelector((Store: RootState) => Store.emails);

  const handlePickDate = (
    from: string | undefined,
    until: string | undefined
  ) => {
    if (from && until) {
      dispatch(
        setDateInterval({
          from,
          until,
        })
      );
    }
  };

  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };
  return (
    <Container>
      <Typography.Text style={{ marginRight: "32px" }}>
        Interval
      </Typography.Text>
      <RangePicker
        defaultValue={[moment(dateInterval.from), moment(dateInterval.until)]}
        disabledDate={disabledDate}
        onChange={(e) => {
          handlePickDate(
            e?.[0]?.utc().format().toString(),
            e?.[1]?.utc().format().toString()
          );
        }}
      />
    </Container>
  );
};
