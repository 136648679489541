import React from "react";
import { Modal, Button, Input } from "antd";
import { BASE_URL } from "../../ApiClient";

export default function EmbedModal(props: any) {
  const { visible, onCancel, row } = props;

  return (
    <Modal
      maskClosable={false}
      title={`Embed Code for ${row.name}`}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button key="cancel" onClick={onCancel}>
          Close
        </Button>
      }
      width="70vw"
    >
      <h3>Weekly Ad</h3>
      <code>
        <Input.TextArea
          readOnly
          autoSize
          style={{ marginBottom: 16, resize: "none" }}
          value={`<link href="${BASE_URL}client/static/css/2.css" rel="stylesheet">
<link href="${BASE_URL}client/static/css/main.css" rel="stylesheet">
<div id="root" data-retailer-id="${row.id}" data-view="weekly-ad"></div>
<script src="${BASE_URL}client/init.js"></script>
<script src="${BASE_URL}client/static/js/2.js"></script>
<script src="${BASE_URL}client/static/js/main.js"></script>`}
        ></Input.TextArea>
      </code>
      <h3>Recipes</h3>
      <code>
        <Input.TextArea
          readOnly
          autoSize
          style={{ marginBottom: 16, resize: "none" }}
          value={`<link href="${BASE_URL}client/static/css/2.css" rel="stylesheet">
<link href="${BASE_URL}client/static/css/main.css" rel="stylesheet">
<div id="root" data-retailer-id="${row.id}" data-view="recipes"></div>
<script src="${BASE_URL}client/init.js"></script>
<script src="${BASE_URL}client/static/js/2.js"></script>
<script src="${BASE_URL}client/static/js/main.js"></script>`}
        />
      </code>
      <h3>Shopping List</h3>
      <code>
        <Input.TextArea
          readOnly
          autoSize
          style={{ marginBottom: 16, resize: "none" }}
          value={`<link href="${BASE_URL}client/static/css/2.css" rel="stylesheet">
<link href="${BASE_URL}client/static/css/main.css" rel="stylesheet">
<div id="root" data-retailer-id="${row.id}" data-view="shopping-list"></div>
<script src="${BASE_URL}client/init.js"></script>
<script src="${BASE_URL}client/static/js/2.js"></script>
<script src="${BASE_URL}client/static/js/main.js"></script>`}
        />
      </code>
      <h3>Store Picker</h3>
      <code>
        <Input.TextArea
          readOnly
          autoSize
          style={{ marginBottom: 16, resize: "none" }}
          value={`<link href="${BASE_URL}client/static/css/2.css" rel="stylesheet">
<link href="${BASE_URL}client/static/css/main.css" rel="stylesheet">
<div id="root" data-retailer-id="${row.id}" data-view="store-picker"></div>
<script src="${BASE_URL}client/init.js"></script>
<script src="${BASE_URL}client/static/js/2.js"></script>
<script src="${BASE_URL}client/static/js/main.js"></script>`}
        />
      </code>
    </Modal>
  );
}
