import React from "react";
import { DatePicker, Form, Input, Select, Upload, Button } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { slug } from "../common/utils/slug";
import { RootState } from "../../App";
import { Circular } from "./models";

const { RangePicker } = DatePicker;

export function CircularsForm(props: {
  form: any;
  initialValues?: Circular | any;
  onSubmit: (v: any) => void;
}) {
  const { form, onSubmit, initialValues } = props;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const retailers = useSelector((state: RootState) => state.retailers.all);
  const currentRetailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  const handleBeforeUpload = (file: any) => {
    return false;
  };

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
      onValuesChange={handleFormChange}
    >
      {!currentRetailerId && (
        <Form.Item
          label="Retailer:"
          name="retailer"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select>
            {retailers.map((retailer, _index) => (
              <Select.Option value={retailer.id} key={retailer.id}>
                {retailer.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="Name "
        name="name"
        rules={[{ required: true, message: "Name is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Slug "
        name="slug"
        rules={[{ required: true, message: "Slug is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={"Active Date:"} name={"dateRange"} rules={[{ required: true, message: "Active Date is required!" }]}>
        <RangePicker
          showTime={{
            defaultValue: [
              moment("00:00:00", "HH:mm:ss"),
              moment("23:59:59", "HH:mm:ss"),
            ],
          }}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        label="Title "
        name="title"
        rules={[{ required: true, message: "Title is required!" }]}
      >
        <Input />
      </Form.Item>
      {!initialValues && (
        <Form.Item
          name="pdfFile"
          label="Upload PDF"
          rules={[{ required: true, message: "PDF File is required!" }]}
        >
          <Upload
            name="pdf"
            action="/"
            beforeUpload={handleBeforeUpload}
            multiple
          >
            <Button>Select Files</Button>
          </Upload>
        </Form.Item>
      )}
    </Form>
  );
}
