import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCirculars } from "./api";
import { Button, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { AddCircularModal } from "./AddCircularModal";
import CircularsTable from "./CircularsTable";
import { showAddCircularModal } from "./slice";
import { RootState } from "../../App";
import { Circular } from "./models";
import { deleteCircular } from "./api";
import moment from "moment";

const { TabPane } = Tabs;

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default function Circulars() {
  const dispatch = useDispatch();
  const activeKey = localStorage.getItem("circular_active_tab")
  const [activeTab, setActiveTab] = useState(activeKey == null ? 'current' : activeKey);
  const handleAddButtonClick = () => {
    dispatch(showAddCircularModal());
  };

  const circulars = useSelector((state: RootState) => state.circulars.all);
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  useEffect(() => {
    dispatch(getCirculars({ retailerId: retailerId }));
  }, [dispatch, retailerId]);

  const expiredCircularDate = () => {
    circulars.map((item) => {
      let terminationDate = moment(item.terminationDate)
      let todayDate = moment()
      let diff = todayDate.diff(terminationDate, "days")
      if (diff > 30) {
        dispatch(deleteCircular(item))
      }
      return (item);
    })
  }

  useEffect(() => {
    expiredCircularDate();
  }, [circulars])

  return (
    <Container>
      <HeaderContainer>
        <h1>Circulars</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddButtonClick}
        >
          Add Circular
        </Button>
        <AddCircularModal />
      </HeaderContainer>
      <Tabs activeKey={activeTab} type="card" onChange={() => {
        if (activeTab == "expired") {
          setActiveTab("current");
          localStorage.setItem("circular_active_tab","current")
        }
        else {
          setActiveTab("expired")
          localStorage.setItem("circular_active_tab","expired")
        }
      }}>
        <TabPane tab="Current Circulars" key="current">
          <CircularsTable
            circulars={circulars
              .filter(
                (circular: Circular) =>
                  circular.terminationDate > new Date().toJSON()
              )
              .map((c) => ({ ...c, key: c.id }))}
          />
        </TabPane>
        <TabPane tab="Expired Circulars" key="expired">
          <CircularsTable
            circulars={circulars
              .filter(
                (circular: Circular) =>
                  circular.terminationDate < new Date().toJSON()
              )
              .map((c) => ({ ...c, key: c.id }))
            }
          />
        </TabPane>
      </Tabs>
    </Container>
  );
}
