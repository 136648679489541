import styled from "styled-components";
import React, { useState } from "react";
import { Button, Input } from "antd";

export const Container = styled.div`
  width: 100%;
  padding: 32px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export interface CategoryRow {
  id: string;
  index: number;
  name: string;
}

export const CategoriesActions = (props: {
  onDelete: () => void;
  row: CategoryRow;
}) => {
  return (
    <Button onClick={props.onDelete} danger style={{ marginLeft: -16 }}>
      Delete
    </Button>
  );
};
export const EditableCell = (props: {
  isUpdating: boolean;
  onUpdate: (row: CategoryRow) => void;
  row: CategoryRow;
}) => {
  const [inputValue, setInputValue] = useState(props.row.name);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Input
        style={{ marginRight: "10px" }}
        onChange={(event) => {
          setInputValue(event.target.value);
        }}
        value={inputValue}
      />
      <Button
        type="primary"
        loading={props.isUpdating}
        onClick={() => {
          props.onUpdate({ ...props.row, name: inputValue });
        }}
        style={{ marginLeft: 6 }}
      >
        Update
      </Button>
    </div>
  );
};
