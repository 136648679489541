import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Divider, Alert, Select, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { addProperty, getProperties } from "./api";
import { RootState } from "../../App";
import { getServices } from "../services/api";
import { clearPropertiesStatus, hideAddPropertyModal } from "./slice";
import { slug } from "../common/utils/slug";
import { getSubservicesByServiceId } from "../subservices/api";
import { BASE_URL } from "../../ApiClient";
import { clearSubservices } from "../subservices/slice";
import { resetRetailerService } from "../services/slice";
import { Service } from "../services/models";
import { Subservice } from "../subservices/models";
import { createAuthConfig } from "../auth/api";


export default function AddPropertyModal(props: any) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [subserviceOptions, setSubserviceOptions] = useState<Subservice[]>([]);
  const retailerId = useSelector(
    (state: RootState) => state.properties.currentProperty?.id
  );
  const retailers = useSelector((state: RootState) => state.retailers.all);

  const visible = useSelector(
    (state: RootState) => state.properties.showAddModal
  );
  const loading = useSelector(
    (state: RootState) => state.properties.addPropertyLoading
  );
  const error = useSelector(
    (state: RootState) => state.properties.addPropertyError
  );

  // const retailerServices = useSelector((state: RootState) => state.services.retailerService);

  const { addError, addSuccess } = useSelector((state: RootState) => state.properties);
  const services = useSelector((state: RootState) => state.services.all);
  const [retailerServices, setRetailerServices] = useState<Service[]>([]);
  const [subserviceArray, setSubserviceArray] = useState<string[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [validStatus, setValidStatus] = useState({ pagesname: false });
  const [subService, setSubService] = useState<any>(null);
  const [propertyp, setPropertyp] = useState<any>('');
  useEffect(() => {
    if (retailerServices.length == 0) {
      setDisableSubmit(true);
    }
    else {
      setDisableSubmit(false);
    }
  }, [retailerServices])

  useEffect(() => {
    if (addSuccess) {
      notification.success({
        message: 'Success Message',
        description: "New property created successfully."
      })
      dispatch(clearPropertiesStatus())
    }
    if (addError) {
      notification.error({
        message: 'Error Message',
        description: "Unable to create new property."
      })
      dispatch(clearPropertiesStatus())
    }
  }, [addSuccess, addError])

  // Submit Form
  const submitForm = async (values: any) => {
    await dispatch(addProperty(values));
    dispatch(getProperties({ retailerId: retailerId }));
  };

  useEffect(() => {
    form.resetFields();
    dispatch(getServices({ retailerId: "" }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
  const [propertyPages, setPropertyPages] = useState<string[]>([])

  const { runNowConnectionLoading: runNowLoading,
    scheduleNowConnectionLoading: scheduleNowLoading,
    showAddModal
  } = useSelector((state: RootState) => state.connections)
  console.log(showAddModal);

  function onSearch(val: any) {
    console.log('search:', val);
  }
  // To add propert 
  useEffect(() => {
    if (!showAddModal) {
      (async () => {
        const result = await fetch(`${BASE_URL}api/GetFacebbokPageList?UserName=Ruchi`, createAuthConfig());
        const { connection_data } = await result.json();
        const pagesName = connection_data.data.map((item: any) => item.name);
        const sortedPages = pagesName.sort((a: string, b: string) => {
          let fa = a.toLowerCase(),
            fb = b.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        console.log(sortedPages);

        setPropertyPages(sortedPages)
      })()
    }
  }, [showAddModal]);



  // Handle Modal Okay
  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        const copy = { ...values };
        if (values.subserviceID == undefined) {
          copy.subserviceID = 0
        }
        if (values.serviceID == undefined) {
          copy.serviceID = 0
        }
        console.log("copy1", copy);
        submitForm(copy);
      })
      .catch((e) => { });
  };

  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();
    setRetailerServices([]);
    dispatch(hideAddPropertyModal());
  };

  // Handle Form Change
  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }
  };

  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  // Handle Change Retailer
  const handleChangeRetailer = async (value: string) => {
    // setRetailer(value);
    setRetailerServices([]);
    setSubserviceOptions([])
    form.resetFields(["serviceID", "subserviceID"])
    const result = await fetch(`${BASE_URL}api/Retailers/${value}`);
    let data = await result.json();
    let serviceArray: string[] = [];
    if (data.service !== null && data.service !== "")
      serviceArray = data.service?.split(",");

    const subserviceArray = data.subservice?.split(",");;
    if (subserviceArray) {
      setSubserviceArray(subserviceArray)
    }
    else {
      setSubserviceArray([])
    }
    if (serviceArray.length > 0) {
      let serviceData = services.filter(item => serviceArray.includes(item.id.toString()))
      if (serviceData.length) {
        setRetailerServices(serviceData)
      }
    }
    else {
      notification.error({
        message: 'Error Message',
        description: "Property can't be added because no services are available for the selected retailer"
      })
    }

  };
  // Handle Change Service
  const handleChangeService = async (value: number) => {

    const result = await fetch(`${BASE_URL}api/getsubservicedetail/${value}`,createAuthConfig());
    const data = await result.json();
    console.log("dataAPI",data);
    
    let subServices = data.subservice.filter((item: any) => subserviceArray.includes(item.id.toString()));
    setPropertyp('');
    setSubserviceOptions(subServices)
    form.setFieldsValue({ serviceID: value });
    form.resetFields(["subserviceID"])
    setSubService(null)
    // dispatch(clearSubservices());
  };
  // Handle Change Subservice
  const handleChangeSubservice = (value: number) => {
    // setSubservice(value);
    setSubService(Number(value))
    setPropertyp('');
    form.setFieldsValue({ subserviceID: Number(value) });
  };

  console.log("form", form.getFieldsValue());


  return (
    <Modal
      maskClosable={false}
      title="Add Property"
      visible={visible}
      okText="SAVE PROPERTY"
      onOk={handleModalOk}
      cancelText="CANCEL"
      onCancel={handleCancel}
      okButtonProps={{
        loading: loading,
        disabled: disableSubmit || loading
      }}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        {...layout}
        form={form}
        name="addProperty"
        initialValues={{
          notes: "",
          pageID: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
        }}
        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Retailer "
          name="retailerID"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select 
            style={{ width: 315 }} 
            onChange={handleChangeRetailer}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {retailers.length && retailers.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          validateTrigger="onBlur"
          rules={[
            { required: true, message: "Unique ID is required!" },
            {
              validator: async (_, value) => {
                let regex = new RegExp("^[a-zA-Z0-9_]*$");
                if (!regex.test(value)) {
                  return Promise.reject("Space and special characters is not allowed.[underscore (_) is allowed.]")
                }
                const result = await fetch(`${BASE_URL}api/GetPropertiesUniqueID?uniqueID=${value}`, createAuthConfig());
                const data = await result.json();
                if (data.message !== "UniqueID already exists") {
                  return Promise.resolve();
                }
                else {
                  return Promise.reject(data.message);
                }
              }
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Page ID:" name="pageID">
          <Input />
        </Form.Item>
        <Form.Item
          label="Property name"
          name="name"
          rules={[{ required: true, message: "Property name is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Address 1" name="address1">
          <Input />
        </Form.Item>
        <Form.Item label="Address 2" name="address2">
          <Input />
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Item label="City" name="city"
            validateTrigger="onInput"
            rules={[
              {
                validator: (rule, value) => {
                  if (value == "") return Promise.resolve()
                  let regex = new RegExp("^[a-zA-Z][\sa-zA-Z]*$");
                  if (regex.test(value)) {
                    return Promise.resolve()
                  }
                  else {
                    return Promise.reject("Number are not allowed.")
                  }
                }
              }]} >
            <Input
              placeholder="City"
              style={{ width: "8rem", marginRight: "5.5em" }}
            />
          </Form.Item>
          <Form.Item
            label=" State"
            name="state"
            validateTrigger="onInput"
            rules={[
              {
                validator: (rule, value) => {
                  if (value == "") return Promise.resolve()
                  let regex = new RegExp("^[a-zA-Z][\sa-zA-Z]*$");
                  if (regex.test(value)) {
                    return Promise.resolve()
                  }
                  else {
                    return Promise.reject("Number are not allowed.")

                  }
                }
              }]} >
            <Input
              placeholder="State"
              style={{ width: "4rem", marginRight: ".5em" }}
            />
          </Form.Item>
          <Form.Item label=" ZIP" name="zip">
            <Input type="number" maxLength={5} placeholder="Zip" style={{ width: "5rem" }} />
          </Form.Item>
        </div>
        <Form.Item label="Notes (Optional)" name="notes">
          <Input.TextArea rows={6} />
        </Form.Item>
        {/* retailerServices.length > 0 ? true : false */}
        <Form.Item
          label="Service "
          name="serviceID"
          // name={!retailerServices.length ?"serviceID":"serviceID1"}
          rules={[{ required: true, message: "Service is required!" }]}
        >
          <Select 
            style={{ width: 315 }}
            disabled={!retailerServices.length}
            onChange={handleChangeService}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >  
          {console.log("retailerServices",retailerServices)
          }
            {
              retailerServices.length && retailerServices.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          label="Subservice "
          name="subserviceID"
        // rules={[{ required: subserviceOptions.length > 0 ? true : false, message: "Subservice is required!" }]}
        >
          <Select 
            style={{ width: 315 }}
            disabled={!subserviceOptions.length}
            onChange={handleChangeSubservice}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {console.log("subserviceOptions", subserviceOptions)
            }
            {subserviceOptions.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Property"
          // name={subserviceOptions.length && subService!=32?"pagename":"pagename1"}
        name="pagename"
        // rules={[{ required: true, message: "Page name is required!" }]}
        >
          <Select
            disabled={!subService || form.getFieldsValue()?.subserviceID!= 32 && !subserviceOptions.length }
            showSearch
            onSearch={onSearch}
            style={{ width: 315 }}
            placeholder="Select Property"
            value={propertyp}
            onChange={(value) => {
              console.log("value",value);
              setPropertyp(value);
              setValidStatus({ ...validStatus, pagesname: true })
            }}
          >
           {console.log("propertyPages",propertyPages)
           }
            {propertyPages.map((pagesname, index) => (
              <Select.Option key={index} value={pagesname}>
                {pagesname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
}