import React, { useEffect, useRef, useState } from "react";
import { Button, message, Tabs } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, fileUploader, getUsers } from "./api";
import { RootState } from "../../App";
import { PlusOutlined } from "@ant-design/icons";
import { AddEditUserModal } from "./AddEditUserModal";
import { Actions } from "../Reducers";
import { UserTable } from "./UserTable";
import { UserModel } from "./models";
import { ConfirmDeleteModal } from "../common/components/ConfirmDeleteModal";

const { TabPane } = Tabs;

const Container = styled.div`
  width: 100%;
  padding: 32px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function Users() {
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state: RootState) => state.login.authData?.claims.Permission ?? []
  );
  const admins = useSelector((state: RootState) => state.users.admins);
  const retailers = useSelector((state: RootState) => state.users.retailers);
  const customers = useSelector((state: RootState) => state.users.customers);

  const showAddModal = useSelector(
    (state: RootState) => state.users.showUserModal
  );
  const error = useSelector((state: RootState) => state.users.error);
  const isLoading = useSelector((state: RootState) => state.users.loading);
  const showDeleteModal = useSelector(
    (state: RootState) => state.users.showDeleteModal
  );
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState({
    userId: "",
  });
  const onDeleteUser = (user: UserModel) => {
    setConfirmDeleteVisible({ userId: user.id });
    dispatch(Actions.users.toggleDeleteModal());
  };
  const onEditUser = (user: UserModel) => {
    dispatch(Actions.users.setInEditUser(user));
    dispatch(Actions.users.toggleUserModal());
  };
  
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );
  

  const [search, setSearch] = useState('')
  const [customerList,setCustomers]=useState([...customers])
  const [adminList,setAdmin]=useState([...admins])
  const [retailerList,setRetailer]=useState(retailers)
  const [uploadFileStatud, setUploadFileStatus] = useState(0)
  const fileName = React.useRef<any>(null);
  // const [uploadFiles, setUploadFiles] = useState()

  useEffect(  ()=>{
    setAdmin(admins)
    setCustomers([...customers])
    setRetailer(retailers)
    dispatch(Actions.users.setInEditUser({}))
  },[admins,customers,retailers,confirmDeleteVisible])

  // }, []);
  const searchAction=()=>{
    console.log('hi');
   const CapsWord= search.toLowerCase()
       .split(' ')
       .map((search) => search.charAt(0).toUpperCase() + search.slice(1))
       .join(' ');   
   setSearch(CapsWord);
    let cus=search?customers.filter(item=>item.firstName.startsWith(CapsWord)):customers;
    let admin=search?admins.filter(item=>item.firstName.startsWith(CapsWord)):admins;
 
    let ret=search?retailers.filter(item=>item.firstName.startsWith(CapsWord)):retailers;
   //  let admin=search?admins.filter(item=>item.firstName==search):admins;
   //  let ret=search?retailers.filter(item=>item.firstName==search):retailers;
   // console.log('customerList',cus,customers);
   setCustomers(cus);
   setAdmin(admin);
   setRetailer(ret);
  }
  useEffect(() => {
    dispatch(getUsers({ retailerId: retailerId }));
 
  }, [dispatch, retailerId, uploadFileStatud]);

  let fileData:any=null
  const getFile=(e:any)=>{
    // let fileData:any=null
    fileData=e.target.files && e.target.files[0]
    // setUploadFiles(fileData) event.target.files && event.target.files[0];
  }

  console.log(fileName?.current?.value,"fileName")
  const uploadFile=()=>{
    if(!fileData){
      return
    }
    let fd = new FormData()
    fd.append('file',fileData)
    fileUploader(fd).then(resp=>{
      if (resp!==0)
      {       
        window.alert('Uploaded successfully')  
        setUploadFileStatus(1)   
      }
      if (fileName && fileName.current) {
        console.log(fileName.current.value);
        fileName.current.value = null
      }
  
     // setUploadFileStatus(1)
      console.log('success',resp)  
    }).catch((err)=>{
      window.alert('File not Uploaded')
      if (fileName && fileName.current) {
        console.log(fileName.current.value);
        fileName.current.value = null
      }
      //window.location.reload()
     
    })
    setUploadFileStatus(0)  
    
  }


  return (
    <>
      <Container>
        <AddEditUserModal
          visible={showAddModal}
          onCancel={() => dispatch(Actions.users.toggleUserModal())}
        />
        <ConfirmDeleteModal
          title={"Delete User"}
          error={error}
          isLoading={isLoading}
          visible={showDeleteModal}
          onCancel={() => {
            setConfirmDeleteVisible({ userId: "" });
            dispatch(Actions.users.toggleDeleteModal());
          }}
          onConfirm={() => {
            dispatch(deleteUser(confirmDeleteVisible.userId));
            // setConfirmDeleteVisible({ visible: false, userId: "" });
          }}
          label={"Are you sure you want to delete this user?"}
        />
        <HeaderContainer>
          <div><h1 style={{marginInline:"16px"}}>Users</h1>          
          <div>
          <input type="text" onChange={(e)=>setSearch(e.target.value)} style={{marginLeft:"16px"}} /> 
          <Button
            type="primary"         
            onClick={()=>{searchAction();}}
            style={{ marginInline: "16px", marginRight:"12" }}
          >
            Search User
          </Button>    
            </div> </div>
          <div>           
          <div><input type={'file'} onChange={getFile} ref={fileName} style={{marginInline:"16px"}} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />&nbsp;
        
          <div><Button
            type="primary"         
            onClick={() => {   
              // document.getElementById('file')?.click()    
              uploadFile()  
            }}
            style={{ marginInline: "16px", marginTop: "12px"}  } 
          >
            Upload
          </Button>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              dispatch(Actions.users.setInEditUser({}));
              dispatch(Actions.users.toggleUserModal());
            }}
            style={{ marginInline: "16px" }}
          >
            Add User
          </Button></div></div>    
          </div>
          
        </HeaderContainer>
        <Tabs type="card">
          {permissions.find((p) => p === "IsAdministrator") !== undefined && (
            <TabPane tab="Administrators" key="admins">
              <UserTable
                users={adminList}
                onDeleteUser={onDeleteUser}
                onEditUser={onEditUser}
              />
            </TabPane>
          )}
          <TabPane tab="Retailers" key="retailers">
            <UserTable
              users={retailerList}
              hasRetailer
              onDeleteUser={onDeleteUser}
              onEditUser={onEditUser}
            />
          </TabPane>
          <TabPane tab="Customers" key="customers">
            <UserTable
              users={customerList}
              hasRetailer
              onDeleteUser={onDeleteUser}
              onEditUser={onEditUser}
            />
          </TabPane>
        </Tabs>
      </Container>
    </>
  );
}
