import { Alert, Button, Form, message, Modal, Upload } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { apiClient } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";
import { CircularPage } from "../circulars/models";
import { circularsSlice } from "../circulars/slice";

const { addCircularPages } = circularsSlice.actions;

export const CloneModal: React.FC<{
  visible: boolean;
  onCancel: () => void;
  circularId: string;
  circularPage: CircularPage | undefined;
}> = ({ visible, onCancel, circularId, circularPage }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [cloneStatus, setCloneStatus] = useState<{
    progress: "loading" | "error" | "";
    message?: string;
  }>({ progress: "" });

  const axiosClonePage = async (form: FormData) => {
    setCloneStatus({ progress: "loading" });
    try {
      const response = await apiClient.post<any>(
        "/api/CircularPages/CloneCircularPages",
        form,
        {
          headers: {
            ...createAuthConfig().headers,
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          },
        }
      );
      setCloneStatus({ progress: "" });

      message.success("The page was cloned successfully");
      handleCancel();
      dispatch(
        addCircularPages({ dataArray: response.data, circularId: circularId })
      );
      console.log("req data isz ", response.data as Array<CircularPage>);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.status === 400) {
        setCloneStatus({
          progress: "error",
          message: [].concat
            .apply([], Object.values(e.response.data.errors))
            .join(" "),
        });
      }

      setCloneStatus({
        progress: "error",
        message: "Something went wrong when cloning the circular",
      });
    }
  };

  const handleFinish = (values: any) => {
    const formData = new FormData();
    if (circularPage) formData.append("CloneCircularPageId", circularPage?.id);

    if (circularId) formData.append("CircularId", circularId);

    values.pdfFile.fileList.forEach((file: any) => {
      formData.append("PdfFiles", file.originFileObj);
    });
    axiosClonePage(formData);
  };
  const handleBeforeUpload = (file: any) => {
    return false;
  };

  const handleCancel = () => {
    if (cloneStatus.progress !== "loading") {
      form.resetFields();
      onCancel();
    }
  };

  return (
    <Modal
      visible={visible}
      title={`Clone ${circularPage?.pageImageFileName}`}
      onCancel={handleCancel}
      footer={
        <>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={form.submit}
            loading={cloneStatus.progress === "loading"}
          >
            Clone Circular Page
          </Button>
        </>
      }
    >
      <>
        {cloneStatus.progress === "error" ? (
          <Alert
            style={{ marginBottom: "8px" }}
            message="Error"
            description={cloneStatus.message}
            type="error"
            showIcon
          />
        ) : null}
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            name="pdfFile"
            label="Upload files for cloning"
            rules={[{ required: true, message: "PDF File is required!" }]}
          >
            <Upload
              beforeUpload={handleBeforeUpload}
              name="pdf"
              action="/"
              multiple
            >
              <Button>Select Files</Button>
            </Upload>
          </Form.Item>
        </Form>
      </>
    </Modal>
  );
};
