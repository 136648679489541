import { createSlice } from "@reduxjs/toolkit";
import { getQuota, getEmailsReport } from "./api";
import { DateInterval, EmailsSliceInterface, EmailStatistic } from "./models";
import moment from "moment";

const initialState: EmailsSliceInterface = {
  emailsStatistics: [],
  quota: null,
  statusGetEmailsReport: { progress: "" },
  statusGetQuota: { progress: "" },
  dateInterval: {
    from: moment.utc().subtract(1, "month").format().toString(),
    until: moment.utc().format().toString(),
  },
};

export const emailsSlice = createSlice({
  initialState,
  name: "emails",
  reducers: {
    setDateInterval: (
      state,
      action: { type: string; payload: DateInterval }
    ) => {
      state.dateInterval = action.payload;
    },
  },
  extraReducers: {
    [getQuota.fulfilled.type]: (state, action) => {
      state.quota = action.payload;
      state.statusGetQuota = { progress: "success" };
    },
    [getQuota.rejected.type]: (
      state,
      action: { type: string; payload: string }
    ) => {
      state.statusGetQuota = { progress: "error", message: action.payload };
    },

    [getQuota.pending.type]: (state) => {
      state.statusGetQuota = { progress: "loading" };
    },
    [getEmailsReport.pending.type]: (state) => {
      state.statusGetEmailsReport = { progress: "loading" };
      state.emailsStatistics = [];
    },

    [getEmailsReport.rejected.type]: (
      state,
      action: { type: string; payload: string }
    ) => {
      state.statusGetEmailsReport = {
        progress: "error",
        message: action.payload,
      };
    },

    [getEmailsReport.fulfilled.type]: (
      state,
      action: { type: string; payload: Array<EmailStatistic> }
    ) => {
      state.statusGetEmailsReport = { progress: "success" };
      state.emailsStatistics = action.payload;
    },
  },
});
