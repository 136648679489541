import React, { useEffect, useState } from "react";
import { Form, Input, Checkbox, Select } from "antd";
import { UserModel } from "./models";
import { FormInstance } from "antd/es/form";
import { useSelector } from "react-redux";
import { RootState } from "../../App";

export const UserForm = (props: {
  form: FormInstance;
  initialValues?: Partial<UserModel>;
  onSubmit: (values: Partial<UserModel>) => void;
}) => {
  const { form, onSubmit } = props;
  const initialValues = useSelector(
    (state: RootState) => state.users.inEditUser
  );
  const retailers = useSelector((state: RootState) => state.retailers.all);
  const userRole = useSelector((state: RootState) => state.auth.user?.role);
  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    initialValues?.role ? initialValues.role : userRole
  );

  useEffect(() => {
    props.form.resetFields();
    setSelectedRole(initialValues?.role ? initialValues.role : userRole);
    props.form.setFieldsValue({ retailer: initialValues.retailer });
    props.form.setFieldsValue({
      role: initialValues.role ? initialValues.role : userRole,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);
  useEffect(() => {}, [selectedRole]);
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="addRetailer"
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <Form.Item
        label="User Role"
        name="role"
        rules={[{ required: true, message: "User role is required!" }]}
        getValueFromEvent={(event) => {
          setSelectedRole(event);
          return event;
        }}
      >
        <Select>
          {userRole === "Administrator" && (
            <Select.Option value={"Administrator"}>
              {"Administrator"}
            </Select.Option>
          )}
          <Select.Option value={"Retailer"}>{"Retailer"}</Select.Option>
          <Select.Option value={"Customer"}>{"Customer"}</Select.Option>
        </Select>
      </Form.Item>
      {userRole === "Administrator" && selectedRole !== "Administrator" && (
        <Form.Item
          label={"Retailer"}
          name={"retailer"}
          valuePropName={"id"}
          getValueFromEvent={(event) => {
            return retailers.find((r) => r.id === event);
          }}
        >
          <Select
            style={{ minWidth: "200px" }}
            defaultValue={initialValues.retailer?.id}
            placeholder={"Select retailer"}
          >
            {retailers.map((r) => (
              <Select.Option value={r.id} key={r.id}>
                {r.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label="First Name"
        name="firstName"
        rules={[{ required: true, message: "First name is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="lastName"
        rules={[{ required: true, message: "Last name is required!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Email is required!" }]}
      >
        <Input />
      </Form.Item>
      {selectedRole === "Customer" && (
        <Form.Item
          label="Email Confirmed"
          name="emailConfirmed"
          valuePropName={"checked"}
        >
          <Checkbox />
        </Form.Item>
      )}
      {/* <Form.Item label="Password" name="password" rules={[{ required: true, message: "Password is required!" }]}> */}
      <Form.Item label="Password" name="password">
        <Input.Password placeholder="Leave blank for unchanged" />
      </Form.Item>
      {/* <Form.Item label="Confirm Password" name="confirmPassword" rules={[{ required: true, message: "Password is required!" }]}> */}
      <Form.Item label="Confirm Password" name="confirmPassword">
        <Input.Password placeholder="Leave blank for unchanged" />
      </Form.Item>
      <Form.Item label="Phone" name="phoneNumber">
        <Input/>
      </Form.Item>
      {selectedRole === "Customer" && (
        <Form.Item label="Bonus Card Number" name="bonusCardNumber">
          <Input />
        </Form.Item>
      )}
    </Form>
  );
};
