import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Table, Button, Modal, Checkbox, Alert, Spin, Divider } from "antd";
import { RootState } from "../../../App";
import { Circular, CircularPage } from "../models";
import { CircularGroup } from "../../circular-groups/models";
import { getCircularGroups } from "../../circular-groups/api";
import { EditButtonAndModal } from "./EditButtonAndModal";
import { createAuthConfig } from "../../auth/api";
import { apiClient } from "../../../ApiClient";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Column } = Table;

const Container = styled.div`
  min-width: 300px;

  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const postPagesForCircular = async (
  cgId: string,
  cId: string,
  pages: CircularPage[]
) => {
  try {
    await apiClient.post<Promise<CircularPage[]> | void>(
      `/api/CircularGroups/${cgId}/PagesForCircular/${cId}`,
      pages,
      createAuthConfig()
    );
  } catch (e) {
    throw new Error("Error adding Circular Pages to Circular Group");
  }
};

const getPagesForCircular = async (cgId: string, cId: string) => {
  try {
    const res = await apiClient.get<CircularPage[]>(
      `/api/CircularGroups/${cgId}/PagesForCircular/${cId}`,
      createAuthConfig()
    );
    return res.data;
  } catch (e) {
    throw new Error("Error loading Circular Pages from Circular Group");
  }
};

interface CloneModalProps {
  circularGroups: CircularGroup[];
  visible: string | null;
  onCancel: () => void;
  circularId: string;
}

const CloneModal: React.FC<CloneModalProps> = ({
  circularGroups,
  visible,
  onCancel,
  circularId,
}) => {
  const [cgsToClone, setCgsToClone] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  const handleChange = (cgId: string) => {
    if (!cgsToClone.find((el: string) => el === cgId)) {
      setCgsToClone([...cgsToClone, cgId]);
    } else {
      setCgsToClone(cgsToClone.filter((el) => el !== cgId));
    }
  };

  const handleOk = async () => {
    setLoading(true);
    const pages = await getPagesForCircular(
      circularGroups.find((cg) => cg.id === visible)?.id as string,
      circularId
    );

    // cgsToClone.forEach(async (cgId) => {
    //   await postPagesForCircular(cgId, circularId, pages);
    // });

    try {
      setError("");
      setSuccess("");
      await Promise.all(
        cgsToClone.map((cgId) => postPagesForCircular(cgId, circularId, pages))
      );
      setSuccess("Successfully cloned circular group");
      setLoading(false);
      dispatch(getCircularGroups({ retailerId: retailerId }));

      //in order to hide the modal after success
      onCancel();
    } catch (err) {
      setError("Error cloning circular group");
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={!!visible}
      title={`Clone Pages from ${
        circularGroups.find((cg: CircularGroup) => cg.id === visible)?.name
      }`}
      okText="Clone"
      onOk={handleOk}
      onCancel={onCancel}
      // okButtonProps={{ disabled: !!success, loading: loading }}
      footer={
        success || error ? (
          <Button onClick={onCancel}>Close</Button>
        ) : (
          <>
            <Button onClick={onCancel} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} loading={loading}>
              Clone to Selected
            </Button>
          </>
        )
      }
    >
      {success && (
        <div style={{ marginBottom: 16 }}>
          <Alert message={success} type="success" />
        </div>
      )}
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      {circularGroups
        .filter((cg) => cg.id !== visible)
        .map((cg) => (
          <div style={{ display: "flex", marginBottom: 16 }}>
            <Checkbox
              checked={!!cgsToClone.find((el: string) => el === cg.id)}
              onChange={() => handleChange(cg.id)}
              style={{ marginRight: 16 }}
            />
            <div>{cg.name}</div>
          </div>
        ))}
    </Modal>
  );
};

export const CircularGroupsList = (props: { circular: Circular }) => {
  const dispatch = useDispatch();
  const [cgToClone, setCgToClone] = useState<string | null>(null);
  const { circular } = props;

  const circularGroups: CircularGroup[] = useSelector((state: RootState) =>
    state.circularGroups.all.filter(
      (c) => c.retailer.id === circular.retailer?.id
    )
  );

  const loading = useSelector(
    (state: RootState) => state.circularGroups.loading
  );

  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  useEffect(() => {
    if (!circularGroups.length) {
      dispatch(getCircularGroups({ retailerId: retailerId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClone = (rowId: string) => {
    setCgToClone(rowId);
  };

  const handleCloneCancel = () => {
    setCgToClone(null);
  };

  return loading ? (
    <Container>
      <Spin indicator={antIcon} />
    </Container>
  ) : circularGroups.length ? (
    <Container>
      <Table
        style={{ width: "100%" }}
        pagination={false}
        dataSource={[...circularGroups].map((cg) => ({
          ...cg,
          retailerName: cg.retailer.name,
          key: cg.id,
        }))}
      >
        <Column title="Circular Groups" dataIndex="name" key="name" />
        <Column
          title=""
          key="actions"
          render={(row: any) => (
            <div style={{ display: "flex" }}>
              <EditButtonAndModal circular={circular} circularGroup={row} />
              <Button type="link" onClick={() => handleClone(row.id)}>
                Clone
              </Button>
            </div>
          )}
        />
      </Table>
      {!!cgToClone && (
        <CloneModal
          circularGroups={circularGroups}
          visible={cgToClone}
          onCancel={handleCloneCancel}
          circularId={circular.id as string}
        />
      )}
    </Container>
  ) : (
    <Container>
      <h1>No circular groups</h1>
    </Container>
  );
};
