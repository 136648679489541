import axios from "axios";

const RAPID_API_KEY = '24cbc6d15fmshebb81cbbe6f8977p178436jsn90ca66ace69a';
const RAPID_API_HOST = 'yummly2.p.rapidapi.com';
const RAPID_HOST= 'https://yummly2.p.rapidapi.com';

const headers = {
  "x-rapidapi-host": RAPID_API_HOST,
  "x-rapidapi-key": RAPID_API_KEY,
};

export const searchRecipes = async (query: string, offset?: number) => {

  try {
    const res = await axios.get<any>(
      // `${RAPID_HOST}/recipes/search`,
      `${RAPID_HOST}/feeds/search`,
      {
        headers,
        params: {
          q: query,
          maxResult: 18,
          start: 0,
        },
      }
    );

    //console.log(res.data.feed, "res");
    // console.log(res.data.feed[0]["tracking-id"], "res")

    // const fullRecipes = await Promise.all(
    //   res.data.feed.map(async (recipe: any) => {
    //     const recInfo = await axios.get<any>(
    //       `${RAPID_HOST}/feeds/${recipe.details.id}/information`,
    //       { headers: headers }
    //     );

    //     console.log(fullRecipes, "fullRecipes")

    //     const sourceUrl = recInfo.data.sourceUrl;
    //     const recExtracted = await axios.get<any>(
    //       `${RAPID_HOST}/recipes/extract?url=${sourceUrl}`,
    //       { headers: headers }
    //     );
    //     return {
    //       ...recipe,
    //       ...recInfo.data,
    //       ...recExtracted.data,
    //     };
    //   })
    // );
    return res.data.feed;
  } catch {
    throw new Error("Error searching recipes");
  }
};
