import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCirculars, getCircularsByid } from "../api";
import { Modal, Upload, Button, Form, Alert, Divider } from "antd";
import { Circular } from "../models";
import { apiClient } from "../../../ApiClient";
import { createAuthConfig } from "../../auth/api";
import { RootState } from "../../../App";
import { useParams } from "react-router";

interface AddNewPagesModalProps {
  circular: Circular;
  visible: boolean;
  hideModal: () => void;
}

export function AddNewPagesModal(props: AddNewPagesModalProps) {
  const { circular, visible, hideModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  let { id } = useParams<{ id: string }>();
  const [error, setError] = useState<string | null>(null);
  const retailerId = useSelector(
    (state: RootState) => state.currentRetailer.currentRetailer?.id
  );

  useEffect(() => {
    setLoading(false);
    setError(null);
  }, [visible]);

  const handleOk = async () => {
    setLoading(true);
    setError(null);
    try {
      await form.validateFields();
      form.submit();
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: any) => {
    const formData = new FormData();
    if (circular.id) {
      formData.append("CircularId", circular?.id);
    }

    values.pdfFile.fileList.forEach((file: any) => {
      formData.append("PdfFiles", file.originFileObj);
    });

    try {
      await apiClient.post<any>(`/api/CircularPages`, formData, {
        headers: {
          ...createAuthConfig().headers,
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
        },
      });
      // dispatch(getCirculars({ retailerId: retailerId }));
      dispatch(getCircularsByid({ id}));
      setLoading(false);
      setError(null);
      hideModal();
    } catch (e) {
      setLoading(false);
      setError("There was an error");
    }
  };

  return (
    <Modal
      maskClosable={false}
      visible={visible}
      title={`Add Pages to ${circular.name}`}
      onCancel={() => hideModal()}
      onOk={handleOk}
      okButtonProps={loading ? { loading: true } : undefined}
      okText="Add Pages"
      destroyOnClose
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="pdfFile"
          label="Upload PDF"
          rules={[{ required: true, message: "PDF File is required!" }]}
        >
          <Upload name="pdf" action="/" beforeUpload={() => false} multiple>
            <Button>Select Files</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
}
