import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Alert, Input, Button, Select } from "antd";
import { RootState } from "../../App";
import { getServices } from "../services/api";
import { getConnections } from "./api";
import { EyeOutlined } from "@ant-design/icons";
import ViewConnectionModal from "./ViewConnectionModal";
import EditConnectionModal from "./EditConnectionModal";
import DeleteConnectionModal from "./DeleteConnectionModal";
import EditICon from "../../resources/ICON-edit-pencil.png";
import DeleteICon from "../../resources/ICON-trash-can-bin-delete-remove.png";
import {
  showDeleteConnectionModal,
  showEditConnectionModal,
  showViewConnectionModal,
} from "./slice";
import moment from "moment";
import { Connection } from "./models";
const { Search } = Input;

export default function ConnectionsTable() {
  const dispatch = useDispatch();
  const storedPageSize = localStorage.getItem("connectionPerPage");
  const [pageSize, setPageSize] = useState<number>(
    storedPageSize == null ? 25 : Number(storedPageSize)
  );
  const connections = useSelector((state: RootState) => state.connections.all);
  const error = useSelector((state: RootState) => state.retailers.error);
  const [rowId, setRowId] = React.useState<number>();
  const [viewId, setViewId] = React.useState<number>();
  const [deleteId, setDeleteId] = React.useState<Connection>();
  const retailerId = useSelector(
    (state: RootState) => state.connections.currentConnection?.id
  );
  const [replica, setReplica] = React.useState<Connection[]>([]);
  const [searchType, setSearchType] = React.useState("name");

  const handleChangeSearch = (e: { target: { value: string } }) => {
    if (e.target.value.length > 0) {
      let filteredData: Connection[] = [];
      switch (searchType) {
        case "name":
          filteredData = connections.filter((data) =>
            data.name.toLowerCase().includes(e.target.value.toLowerCase())
          );
          break;
        default:
          break;
      }
      setReplica(filteredData);
    } else {
      setReplica(connections);
    }
  };

  const orderStorageName = "connection_order";
  let localOrder = localStorage.getItem(orderStorageName);
  const [order, setOrder] = useState<string | null>(localOrder);
  const [jsonOrder, setJsonOrder] = useState(
    JSON.parse(
      order !== null
        ? order
        : JSON.stringify({ order: "ascend", column: "name" })
    )
  );
  const [columnAttr, setcolumnAttr] = React.useState<object>({
    sortOrder: jsonOrder?.order == "ascend" ? "ascend" : "descend",
    sortDirections: ["ascend", "descend", "ascend"],
  });
  useEffect(() => {
    if (order == null) {
      localStorage.setItem(
        orderStorageName,
        JSON.stringify({ order: "ascend", column: "name" })
      );
      setOrder(JSON.stringify({ order: "ascend", column: "name" }));
      setJsonOrder({ order: "ascend", column: "name" });
    }
    if (order !== null) {
      setJsonOrder(JSON.parse(order));
    }
  }, [order]);

  const onHeaderCell = (columnName: string) => {
    return {
      onClick: () => {
        if (jsonOrder.order == "descend") {
          localStorage.setItem(
            orderStorageName,
            JSON.stringify({ order: "ascend", column: columnName })
          );
          setOrder(JSON.stringify({ order: "ascend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "ascend" });
        } else {
          localStorage.setItem(
            orderStorageName,
            JSON.stringify({ order: "descend", column: columnName })
          );
          setOrder(JSON.stringify({ order: "descend", column: columnName }));
          setcolumnAttr({ ...columnAttr, sortOrder: "descend" });
        }
      },
    };
  };

  const { deleteSuccess } = useSelector(
    (state: RootState) => state.connections
  );
  useEffect(() => {
    dispatch(getConnections({ retailerId: retailerId }));
  }, [deleteSuccess]);

  useEffect(() => {
    setReplica(connections);
  }, [connections]);

  // Handle View Button Click
  const handleViewButtonClick = (row: any) => {
    setViewId(row.id);
    dispatch(getServices({ retailerId: "" }));
    dispatch(showViewConnectionModal(row.id));
  };
  // handle Edit Button Click
  const handleEditButtonClick = (row: any) => {
    setRowId(row.id);
    dispatch(getServices({ retailerId: "" }));

    dispatch(showEditConnectionModal(row.id));
  };
  // handle Delete Button Click
  const handleDeleteButtonClick = (row: any) => {
    setDeleteId(row.id);
    dispatch(showDeleteConnectionModal(row.id));
  };

  // const connectionTypeMap = {
  //   'IstRunatInterval': 'Car',
  //   'IstRunImmediatethenatInterval': 'Taxi',
  //   'RunOnce': 'Cab',
  //   'SaveAsTemplate': 'Cab',
  //   'UpdateOnly': 'Cab',
  // };

  const columns = [
    {
      title: "SL No",
      dataIndex: "index",
      key: "index",
      sorter: (a: any, b: any) => {
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      },
      ...(jsonOrder.column == "index" && columnAttr),
      onHeaderCell: () => onHeaderCell("index"),
    },
    {
      title: "Connection Name",

      dataIndex: "name",
      key: "name",
      //It will sort data
      sorter: (a: any, b: any) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      ...(jsonOrder.column == "name" && columnAttr),
      onHeaderCell: () => onHeaderCell("name"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (value: string) => {
        if (value == "") return "N/A";
        else
          return (
            <p style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
              {value}
            </p>
          );
      },
      sorter: (a: any, b: any) => {
        if (a.description < b.description) {
          return -1;
        }
        if (a.description > b.description) {
          return 1;
        }
        return 0;
      },
      ...(jsonOrder.column == "description" && columnAttr),
      onHeaderCell: () => onHeaderCell("description"),
    },
    {
      title: "Created On",
      key: "created_at",
      sorter: (a: any, b: any) => {
        if (a.created_at < b.created_at) {
          return -1;
        }
        if (a.created_at > b.created_at) {
          return 1;
        }
        return 0;
      },
      render: (row: any) => (
        <p>{moment(row.created_at).format("MM/DD/YYYY")}</p>
      ),
      ...(jsonOrder.column == "created_at" && columnAttr),
      onHeaderCell: () => onHeaderCell("created_at"),
    },
    {
      title: "Connection Type",
      dataIndex: "connectionAndIntervalType",
      key: "connectionAndIntervalType",
      // render: (text: any ) => connectionTypeMap[text] || text,
      render: (text:any) => {
        switch (text) {
          case 'IstRunatInterval':
            return 'Connection';
          case 'IstRunImmediatethenatInterval':
            return 'Connection';
          case 'RunOnce':
            return 'Connection';
          case 'SaveAsTemplate':
            return 'Template';
            case 'UpdateOnly':
            return 'Template';
          default:
            return text;
        }
      },
      sorter: (a: any, b: any) => {
        if (a.connectionAndIntervalType < b.connectionAndIntervalType) {
          return -1;
        }
        if (a.connectionAndIntervalType > b.connectionAndIntervalType) {
          return 1;
        }
        return 0;
      },
      ...(jsonOrder.column == "connectionAndIntervalType" && columnAttr),
      onHeaderCell: () => onHeaderCell("connectionAndIntervalType"),
    },
    {
      title: "View",
      key: "view",
      align: "center" as const,
      render: (row: any) => (
        <div>
          <Button
            title="View"
            type="link"
            onClick={() => handleViewButtonClick(row)}
          >
            <EyeOutlined
              style={{
                color: "#565050",
                height: "24px",
                width: "24px",
                margin: "0 0.8em",
              }}
            />
          </Button>
          {viewId == row.id && <ViewConnectionModal row={row} />}
        </div>
      ),
    },

    {
      title: "Edit",
      key: "edit",
      align: "center" as const,
      render: (row: any) => (
        <div>
          <Button
            title="Edit"
            type="link"
            onClick={() => handleEditButtonClick(row)}
          >
            <img
              src={EditICon}
              alt="Edit Icon"
              style={{ height: "24px", width: "24px", margin: "0 0.8em" }}
            />
          </Button>
          {rowId !== undefined && rowId == row.id && (
            <EditConnectionModal row={row} />
          )}
        </div>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      align: "center" as const,
      render: (row: any) => (
        <div>
          <Button
            title="Delete"
            type="link"
            danger
            onClick={() => handleDeleteButtonClick(row)}
          >
            <img
              src={DeleteICon}
              alt="Delete Icon"
              style={{ height: "24px", width: "24px", margin: "0 0.8em" }}
            />
          </Button>
          {deleteId && deleteId == row.id && (
            <DeleteConnectionModal row={row} />
          )}
        </div>
      ),
    },
  ];
  const handleChangeSearchType = (value: string) => {
    setSearchType(value);
  };
  return error ? (
    <Alert message="Error" description={error} type="error" showIcon />
  ) : (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <Select
            defaultValue="name"
            style={{ width: 200 }}
            options={[{ value: "name", label: "Connection Name" }]}
            onChange={handleChangeSearchType}
          />
        </div>
        <Search
          placeholder="Search here"
          style={{ width: 300, marginBottom: "1em", marginLeft: "1em" }}
          allowClear
          onChange={handleChangeSearch}
        />
        <p style={{ width: 300, marginBottom: "1em", marginLeft: "1em" }}>
          Total Count: &nbsp; {replica.length}{" "}
        </p>
      </div>
      <Table
        pagination={{
          hideOnSinglePage: false,
          pageSize: pageSize,
          pageSizeOptions: ["25", "50", "100", "200"],
          showSizeChanger: true,
          locale: { items_per_page: "" },
          onShowSizeChange: (current, size) => {
            localStorage.setItem("connectionPerPage", size.toString());
            setPageSize(size ? size : replica.length);
          },
        }}
        dataSource={[...replica]
          .reverse()
          .map((el, index) => ({ ...el, index: index + 1, key: el.id }))}
        columns={columns}
      />
    </>
  );
}
