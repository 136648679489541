import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteCircular, editCircular, getCirculars } from "./api";
import { Circular, CircularPage } from "./models";

export type CircularsState = {
  all: Circular[];
  currentCircular: Circular | null;
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addLoading: boolean;
  addError: string | null;
  showEditModal: string | null;
  editLoading: boolean;
  editError: string | null;
  showDeleteModal: string | null;
  deleteLoading: boolean;
  deleteError: string | null;
  deleteSuccess : boolean;
};

const initialState: CircularsState = {
  all: [],
  loading: false,
  currentCircular: null,
  error: null,
  addError: null,
  showAddModal: false,
  addLoading: false,
  showEditModal: null,
  editLoading: false,
  editError: null,
  showDeleteModal: null,
  deleteLoading: false,
  deleteError: null,
  deleteSuccess : false,
};

export const circularsSlice = createSlice({
  name: "circulars",
  initialState,
  reducers: {
    addCircular: (state, action) => {
      state.all = [...state.all, action.payload];
      state.addLoading = false;
      state.showAddModal = false;
      state.addError = null;
    },
    setAddCircularLoading: (state, action) => {
      state.addLoading = action.payload;
      state.addError = null;
    },
    setAddCircularError: (state, action) => {
      state.addError = action.payload;
    },
    showAddCircularModal: (state) => {
      state.showAddModal = true;
    },
    hideAddCircularModal: (state) => {
      state.showAddModal = false;
      state.addError = null;
      state.addLoading = false;
    },
    editCircular: (state, action) => {
      state.all = state.all.map((circular) =>
        circular.id === action.payload.id ? action.payload : circular
      );
      state.editLoading = false;
      state.showEditModal = null;
      state.editError = null;
    },

    setEditCircularLoading: (state, action) => {
      state.editLoading = true;
      state.editError = null;
    },
    setEditCircularError: (state, action) => {
      state.editError = action.payload;
      state.editLoading = false;
    },
    showEditCircularModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditCircularModal: (state) => {
      state.showEditModal = null;
      state.editError = null;
    },
    showDeleteCircularModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeleteCircularModal: (state) => {
      state.showDeleteModal = null;
      state.deleteError = null;
    },

    addCircularPages: (
      state,
      action: PayloadAction<{
        dataArray: Array<CircularPage>;
        circularId: string;
      }>
    ) => {
      state.all = state.all.map((circular) => {
        if (circular.id !== action.payload.circularId) return circular;
        else
          return {
            ...circular,
            circularPages: [
              ...circular.circularPages,
              ...action.payload.dataArray,
            ],
          };
      });
    },
  },
  extraReducers: (builder) => {
    /* get */
    builder.addCase(getCirculars.pending, (state) => {
      state.loading = true;
      state.currentCircular = null;
      state.error = null;
    });
    builder.addCase(getCirculars.fulfilled, (state, action) => {
      state.loading = false;
      state.all = action.payload;
      state.error = null;
    });
    builder.addCase(getCirculars.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add */
    // builder.addCase(addCircular.pending, (state) => {
    //   state.addLoading = true;
    //   state.addError = null;
    // });
    // builder.addCase(addCircular.fulfilled, (state, action) => {
    //   state.addLoading = false;
    //   state.addError = null;
    //   state.showAddModal = false;
    //   state.all = [...state.all, action.payload];
    // });
    // builder.addCase(addCircular.rejected, (state, action) => {
    //   state.addLoading = false;
    //   if (typeof action.payload === "string") {
    //     state.addError = action.payload;
    //   }
    // });

    /* edit */
    builder.addCase(editCircular.pending, (state) => {
      state.editLoading = true;
      state.editError = null;
    });
    builder.addCase(editCircular.fulfilled, (state, action) => {
      state.editLoading = false;
      state.editError = null;
      state.showEditModal = null;
      state.all = state.all.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    });
    builder.addCase(editCircular.rejected, (state, action) => {
      state.editLoading = false;
      if (typeof action.payload === "string") {
        state.editError = action.payload;
      }
    });

    /* delete */
    builder.addCase(deleteCircular.pending, (state) => {
      state.deleteLoading = true;
      state.deleteError = null;
    });
    builder.addCase(deleteCircular.fulfilled, (state, action) => {
      state.all = state.all.filter((item) => item.id !== action.payload.id);
      state.deleteLoading = false;
      state.showDeleteModal = null;
      state.deleteError = null;
      state.deleteSuccess = true;
    });
    builder.addCase(deleteCircular.rejected, (state, action) => {
      state.deleteLoading = false;
      if (typeof action.payload === "string") {
        state.deleteError = action.payload;
      }
    });
  },
});

export const {
  addCircular,
  setAddCircularLoading,
  setAddCircularError,
  showAddCircularModal,
  hideAddCircularModal,
  showEditCircularModal,
  hideEditCircularModal,
  setEditCircularLoading,
  setEditCircularError,
  showDeleteCircularModal,
  hideDeleteCircularModal,
} = circularsSlice.actions;
