import React from "react";
import { Modal, Form, Divider, Alert } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import CircularGroupForm from "./CircularGroupForm";
import { editCircularGroup } from "./api";
import { CircularGroup } from "./models";
import { hideEditCircularGroupModal } from "./slice";

export default function EditCircularGroupModal(props: { row: CircularGroup }) {
  const { row } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const visible = useSelector(
    (state: RootState) => state.circularGroups.showEditModal === row.id
  );

  const handleSubmit = (values: any) => {
    dispatch(editCircularGroup({ id: row.id, ...values }));
  };

  const loading = useSelector(
    (state: RootState) => state.circularGroups.editLoading
  );

  const error = useSelector(
    (state: RootState) => state.circularGroups.editError
  );

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        // form.resetFields();
        handleSubmit(values);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(hideEditCircularGroupModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Edit Circular Group"
      visible={visible}
      onCancel={handleCancel}
      okText="Update"
      onOk={handleModalOk}
      okButtonProps={loading ? { loading: true } : undefined}
      destroyOnClose
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <CircularGroupForm form={form} initialValues={row} />
    </Modal>
  );
}
