import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getRetailers, addRetailer, editRetailer, deleteRetailer } from "./api";
import { Retailer } from "./models";

export type RetailerState = {
  all: Retailer[];
  currentRetailer?: Retailer;
  loading: boolean;
  error: string | null;
  showAddModal: boolean;
  addRetailerLoading: boolean;
  addRetailerError: string | null;
  showEditModal: string | null;
  editRetailerLoading: boolean;
  editRetailerError: string | null;
  showDeleteModal: string | null;
  deleteRetailerLoading: boolean;
  deleteRetailerError: string | null;
  addSuccess: boolean;
  addError: boolean;
  editSuccess: boolean;
  editError: boolean;
  deleteSuccess: boolean;
  deleteError: boolean;
};

const initialState: RetailerState = {
  all: [],
  currentRetailer: undefined,
  loading: false,
  error: null,
  addRetailerError: null,
  showAddModal: false,
  addRetailerLoading: false,
  showEditModal: null,
  editRetailerLoading: false,
  editRetailerError: null,
  showDeleteModal: null,
  deleteRetailerLoading: false,
  deleteRetailerError: null,
  addSuccess: false,
  addError: false,
  editSuccess: false,
  editError: false,
  deleteSuccess: false,
  deleteError: false,

};

export const retailersSlice = createSlice({
  name: "retailers",
  initialState,
  reducers: {
    setCurrentRetailer: (state, action: PayloadAction<Retailer>) => {
      state.currentRetailer = action.payload;
      state.editRetailerError = null;
    },
    showAddRetailerModal: (state) => {
      state.showAddModal = true;
    },
    hideAddRetailerModal: (state) => {
      state.showAddModal = false;
      state.addRetailerError = null;
    },
    showEditRetailerModal: (state, action) => {
      state.showEditModal = action.payload;
    },
    hideEditRetailerModal: (state) => {
      state.showEditModal = null;
      state.editRetailerError = null;
    },
    showDeleteRetailerModal: (state, action) => {
      state.showDeleteModal = action.payload;
    },
    hideDeleteRetailerModal: (state) => {
      state.showDeleteModal = null;
      state.deleteRetailerError = null;
    },
    resetMessages: (state) => {
      state.addSuccess = false
      state.addError = false
      state.editSuccess = false
      state.editError = false
      state.addSuccess = false
      state.deleteSuccess = false
      state.deleteError = false
    }
  },
  extraReducers: (builder) => {
    /* get retailers */
    builder.addCase(getRetailers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRetailers.fulfilled, (state, action) => {
      state.all = action.payload.sort((a, b) => {
        let fa = a.name.toLowerCase(),
          fb = b.name.toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      state.loading = false;
      state.editRetailerError = null;
      state.error = null;
    });
    builder.addCase(getRetailers.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.error = action.payload;
      }
    });

    /* add retailer*/
    builder.addCase(addRetailer.pending, (state) => {
      state.addRetailerLoading = true;
      state.addRetailerError = null;
      state.addSuccess = false;
      state.addError = false;
    });
    builder.addCase(addRetailer.fulfilled, (state, action) => {
      state.all = [...state.all, action.payload];
      state.addRetailerLoading = false;
      state.addRetailerError = null;
      state.showAddModal = false;
      state.addSuccess = true;
      state.addError = false;
    });
    builder.addCase(addRetailer.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.addRetailerError = action.payload;
        state.addRetailerLoading = false;
        state.addSuccess = false;
        state.addError = true;
      }
    });

    /* edit retailer*/
    builder.addCase(editRetailer.pending, (state) => {
      state.editRetailerLoading = true;
      state.editRetailerError = null;
      state.editSuccess = false;
      state.editError = false;
    });
    builder.addCase(editRetailer.fulfilled, (state, action) => {
      // state.all = [...state.all, action.payload];
      state.editSuccess = true;
      state.editError = false;

      state.all = state.all.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
      state.editRetailerLoading = false;
      state.editRetailerError = null;
      // state.showEditModal = null;

    });
    builder.addCase(editRetailer.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.editRetailerError = action.payload;
        state.editRetailerLoading = false;
        state.editSuccess = false;
        state.editError = true;
      }
    });

    /* delete retailer*/
    builder.addCase(deleteRetailer.pending, (state) => {
      state.deleteSuccess = false;
      state.deleteError = false;
      state.deleteRetailerLoading = true;
      state.deleteRetailerError = null;
    });
    builder.addCase(deleteRetailer.fulfilled, (state, action) => {
      state.deleteSuccess = true;
      state.deleteError = false;
      state.all = state.all.filter((item) => item.id !== action.payload.id);
      state.deleteRetailerLoading = false;
      state.showDeleteModal = null;
      state.deleteRetailerError = null;

    });
    builder.addCase(deleteRetailer.rejected, (state, action) => {
      if (typeof action.payload === "string") {
        state.deleteSuccess = false;
        state.deleteError = true;
        state.deleteRetailerError = action.payload;
        state.deleteRetailerLoading = false;
      }
    });

  },
});

export const {
  showAddRetailerModal,
  hideAddRetailerModal,
  showEditRetailerModal,
  hideEditRetailerModal,
  showDeleteRetailerModal,
  hideDeleteRetailerModal,
  resetMessages
} = retailersSlice.actions;
