import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showEditRetailerModal, showDeleteRetailerModal, resetMessages } from "./slice";
import { Button } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import EmbedModal from "./EmbedModal";
import EditRetailerModal from "./EditRetailerModal";
import DeleteRetailerModal from "./DeleteRetailerModal";
import { getServices } from "../services/api";
import { getSubservices } from "../subservices/api";
import { RootState } from "../../App";

export default function ActionsRow(props: {
  row: {
    key: string;
    name: string;
    slug: string;
    id: string;
    from: string;
    replyTo: string;
    siteName: string;
    siteUrl: string;
    logoUrl: string;
    service: string;
    subservice: string;
  };
}) {
  const dispatch = useDispatch();
  const { row } = props;
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const [editId, setEditId] = useState<string>();
  const visibleId = useSelector(
    (state: RootState) => state.retailers.showEditModal
  );
  const [deleteId, setDeleteId] = useState<string>();
  const handleEditButtonClick = (id: any) => {
    setEditId(id);
    dispatch(resetMessages());
    dispatch(getServices({ retailerId: '1' }))
    dispatch(getSubservices({ retailerId: '1' }))
    dispatch(showEditRetailerModal(row.id));
  };

  const handleDeleteButtonClick = () => {
    setDeleteId(row.id);
    dispatch(showDeleteRetailerModal(row.id));
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button type="link" onClick={() => setShowEmbedModal(true)}>
          Embed Code
        </Button>
        <EmbedModal
          visible={showEmbedModal}
          onCancel={() => setShowEmbedModal(false)}
          row={row}
        />

        <Button
          type="link"
          icon={<LinkOutlined />}
          href={row.siteUrl}
          target="_blank"
        >
          Retailer Page
        </Button>

        <Button type="link" onClick={() => handleEditButtonClick(row.id)}>
          Edit
        </Button>
        {
          (editId && editId == row.id) &&
          <EditRetailerModal row={row} />
        }

        <Button type="link" danger onClick={handleDeleteButtonClick}>
          Delete
        </Button>
        {
          (deleteId && deleteId == row.id) &&
          <DeleteRetailerModal row={row} />
        }
      </div>
    </>
  );
}
