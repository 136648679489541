import React from "react";
import { Modal, Form, Alert, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../App";
import CircularGroupForm from "./CircularGroupForm";
import { addCircularGroup } from "./api";

export default function AddCircularGroupModal(props: any) {
  const { visible, onCancel } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSubmit = (values: any) => {
    dispatch(addCircularGroup(values));
  };

  const loading = useSelector(
    (state: RootState) => state.circularGroups.addLoading
  );

  const error = useSelector(
    (state: RootState) => state.circularGroups.addError
  );

  const handleModalOk = () => {
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        handleSubmit(values);
      })
      .catch((e) => {});
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      maskClosable={false}
      title="Add Circular Group"
      visible={visible}
      onCancel={handleCancel}
      okText="Create"
      onOk={handleModalOk}
      okButtonProps={loading ? { loading: true } : undefined}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <CircularGroupForm form={form} />
    </Modal>
  );
}
