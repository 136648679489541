import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Alert, Divider, Select, notification } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { editProperty, getProperties } from "./api";
import { RootState } from "../../App";

import { clearPropertiesStatus, hideEditPropertyModal, showEditPropertyModal } from "./slice";
import { slug } from "../common/utils/slug";
import { getServices } from "../services/api";

import { Service } from "../services/models";
import { Subservice } from "../subservices/models";
import { BASE_URL } from "../../ApiClient";
import { createAuthConfig } from "../auth/api";
import moment from "moment";

export default function EditModal(props: {
  row: {
    name: string;
    retailerID: string;
    id: string;
    uniqueID: string;
    pageID: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    notes: string;
    serviceID: number;
    subserviceID: number;
    username: string;
    password: string;
    apiKey: string;
    apikey?: string;
    pagename: string;
    created_at: string;
    updated_at: string;
  };
}) {
  const { row } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [subserviceOptions, setSubserviceOptions] = useState<Subservice[]>([]);
  const retailers = useSelector((state: RootState) => state.retailers.all);

  const visible = useSelector(
    (state: RootState) => state.properties.showEditModal === row.id
  );

  const retailerId = useSelector(
    (state: RootState) => state.properties.currentProperty?.id
  );

  const loading = useSelector(
    (state: RootState) => state.properties.editPropertyLoading
  );

  const error = useSelector(
    (state: RootState) => state.properties.editPropertyError
  );
  // Submit Form
  const property = useSelector((state: RootState) => state.properties);
  const services = useSelector((state: RootState) => state.services.all);
  const [retailerServices, setRetailerServices] = useState<Service[]>([]);
  const [subserviceArray, setSubserviceArray] = useState<string[]>([]);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [isLoadingSub, setIsLoadingSub] = useState<boolean>(false);
  const [validStatus, setValidStatus] = useState({ pagesname: false });
  const [subService, setSubService] = useState<any>(null);
  const [propertyp, setPropertyp] = useState<any>(null);

  //Get login user detail
  const loggedInUser = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (property.editSuccess && !visible) {
      notification.success({
        message: 'Success Message',
        description: `${row.name} property updated successfully.`
      })
      dispatch(clearPropertiesStatus())
    }
    if (property.editError) {
      notification.error({
        message: 'Error Message',
        description: `Unable to update ${row.name} property.`
      })
      dispatch(clearPropertiesStatus())
    }
  }, [property.editError, property.editSuccess])

  useEffect(() => {
    dispatch(getServices({ retailerId: "" }));
    (async () => {
      await setServices(row.retailerID, false);
    })()

  }, [])

  useEffect(() => {
    (async () => {
      await setSubservice(row.serviceID, false);
    })()
  }, [subserviceArray])

  const submitForm = async (values: any) => {
    await dispatch(editProperty({ ...row, ...values }));
    dispatch(showEditPropertyModal(null));
    dispatch(getProperties({ retailerId: retailerId }));
  };

  const [propertyPages, setPropertyPages] = useState<string[]>([])

  const { runNowConnectionLoading: runNowLoading,
    scheduleNowConnectionLoading: scheduleNowLoading,
    showEditModal
  } = useSelector((state: RootState) => state.connections)
  console.log(showEditModal);

  function onSearch(val: any) {
    console.log('search:', val);
  }
  // To add propert 
  useEffect(() => {
    if (!showEditModal) {
      (async () => {
        const result = await fetch(`${BASE_URL}api/GetFacebbokPageList`, createAuthConfig());
        const { connection_data } = await result.json();
        const pagesName = connection_data.data.map((item: any) => item.name);
        const sortedPages = pagesName.sort((a: string, b: string) => {
          let fa = a.toLowerCase(),
            fb = b.toLowerCase();
          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        console.log(sortedPages);

        setPropertyPages(sortedPages)
      })()
    }
    setPropertyp(row.pagename)
  }, [showEditModal]);

  // Handle Modal Okay
  const handleModalOk = () => {
    //validates values then sends them to submitForm()
    form
      .validateFields()
      .then((values) => {
        if (values.serviceID == "") {
          values.serviceID = 0
        }
        if (values.subserviceID == "") {
          values.subserviceID = 0
        }
        submitForm(values);
      })
      .catch((e) => { });
  };
  // Handle Cancel
  const handleCancel = () => {
    form.resetFields();
    dispatch(hideEditPropertyModal());
  };

  // Handle Form Change

  const handleFormChange = (changedValues: any, allValues: any) => {
    if (changedValues.name && slug(changedValues.name) !== allValues.slug) {
      form.setFieldsValue({
        name: allValues.name,
        slug: slug(allValues.name),
      });
    }


  };

  // layout object
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  // Handle Change Retailer
  const handleChangeRetailer = async (value: string) => {
    form.setFieldsValue({ serviceID: "" })
    form.setFieldsValue({ subserviceID: "" })
    await setServices(value, true)

  };

  const setServices = async (retailerId: string, clear: boolean = true) => {
    if (clear) {
      setRetailerServices([]);
      setSubserviceOptions([])
      form.setFieldsValue({ serviceID: "" })
      form.setFieldsValue({ subserviceID: "" })
    }

    const result = await fetch(`${BASE_URL}api/Retailers/${retailerId}`);
    let data = await result.json();
    let serviceArray: string[] = [];
    if (data.service !== null && data.service !== "")
      serviceArray = data.service?.split(",");

    const RetsubserviceArray = data.subservice?.split(",");

    if (RetsubserviceArray) {
      setSubserviceArray(RetsubserviceArray)
    }
    else {
      setSubserviceArray([])
    }
    if (serviceArray.length > 0) {
      let serviceData = services.filter(item => serviceArray.includes(item.id.toString()))
      if (serviceData.length) {
        setRetailerServices(serviceData)
      }
    }
    else {
      notification.error({
        message: 'Error Message',
        description: "Property can't be update because no services are available for the selected retailer"
      })
    }
  }

  useEffect(() => { 
    if (retailerServices.length == 0) {
      setDisableSubmit(true);
      console.log("abhi",retailerServices)
   
    }
    else {
      setDisableSubmit(false);
    }
  }, [retailerServices])
  // Handle Change Service
  const handleChangeService = async (value: number) => {
    setSubService("")
    console.log("abbh0",value)
    row.pagename = '';
    setPropertyp('');
    await setSubservice(value);
  }

  const setSubservice = async (serviceId: number, clear = true) => {
    if (clear) {
      form.setFieldsValue({ subserviceID: "" });
      setSubserviceOptions([])
    }

    const result = await fetch(`${BASE_URL}api/getsubservicedetail/${serviceId}`,createAuthConfig());
    const data = await result.json();
    let subServices = data.subservice.filter((item: any) => subserviceArray.includes(item.id.toString()));

    setSubserviceOptions(subServices)
  }

  // Handle Change Subservice
  const handleChangeSubservice = (value: string) => {
    setSubService(Number(value))

    setPropertyp('');
  };

  return (
    <Modal
      maskClosable={false}
      title="Edit Property"
      visible={visible && !isLoadingSub}
      okText="Update"
      onOk={handleModalOk}
      onCancel={handleCancel}
      okButtonProps={{
        loading: loading,
        disabled: disableSubmit || loading
      }}
      destroyOnClose={true}
    >

      {
        row.updated_at == null || row.updated_at === ''
        ? (<span style={{ float: 'right' }}><b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")} {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>)
        : (
            <>
              <span style={{ float: 'right' }}><b>Created: </b> {moment(row.created_at).format("MM/DD/YYYY")} {moment(row.created_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>
              <br/>
              <span style={{ float: 'right' }}><b>Last Edited: </b> {moment(row.updated_at).format("MM/DD/YYYY")} {moment(row.updated_at).format("hh:mma")} by {loggedInUser?.firstName} {loggedInUser?.lastName}</span>
            </>
          )
      }
      <br/><br/>

      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <Form
        // row.serviceID == 0 ? "" : row.serviceID
        {...layout}
        form={form}
        name={`editProperty#${row.id}`}
        initialValues={{
          name: row.name,
          retailerID: row.retailerID,
          id: row.id,
          uniqueID: row.uniqueID,
          pageID: row.pageID,
          address1: row.address1,
          address2: row.address2,
          city: row.city,
          state: row.state,
          zip: row.zip,
          notes: row.notes,
          serviceID: row.serviceID == 0 ? "" : row.serviceID,
          subserviceID: row.subserviceID == 0 ? "" : row.subserviceID,
          username: row.username,
          password: row.password,
          apikey: row.apikey,
          pagename:row.pagename
          
        }}

        onValuesChange={handleFormChange}
      >
        <Form.Item
          label="Retailer "
          name="retailerID"
          rules={[{ required: true, message: "Retailer is required!" }]}
        >
          <Select 
            style={{ width: 315 }} 
            onChange={handleChangeRetailer}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {retailers.length && retailers.map((option) => (
              <Select.Option key={option.id} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Unique ID:"
          name="uniqueID"
          rules={[{ required: true, message: "Unique ID is required!" }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Page ID:"
          name="pageID"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Property name"
          name="name"
          rules={[{ required: true, message: "Property name is required!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address 1"
          name="address1"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Address 2"
          name="address2"
        >
          <Input />
        </Form.Item>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Form.Item
            label="City"
            name="city"
            validateTrigger="onBlur"
          
          >
            <Input
              placeholder="City"
              style={{ width: "8rem", marginRight: "5.5em" }}
            />
          </Form.Item>
          <Form.Item
            label=" State"
            name="state"
            validateTrigger="onBlur"        
          >
            <Input
              placeholder="State"
              style={{ width: "4rem", marginRight: ".5em" }}
            />
          </Form.Item>
          <Form.Item label=" ZIP" name="zip">
            <Input type="number" maxLength={5} placeholder="Zip" style={{ width: "5rem" }} />
          </Form.Item>
        </div>
        <Form.Item label="Notes (Optional)" name="notes">
          <Input.TextArea rows={6} />
        </Form.Item>
        {/* retailerServices.length > 0 ? true : false */}
        <Form.Item
          label="Service "
          name="serviceID"
          rules={[{ required: true, message: "Service is required!" }]}
        >
          <Select
            style={{ width: 315 }}
            disabled={!retailerServices.length}
            onChange={handleChangeService}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {
              retailerServices.length && retailerServices.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        {/* subserviceOptions.length > 0 ? true : false */}
        <Form.Item
          label="Subservice"
          name=  "subserviceID"
        // rules={[{ required: false, message: "Subservice is required!" }]}
        >
          <Select 
            style={{ width: 315 }}
            // value={row.subserviceID?.toString()}
            allowClear
            disabled={!subserviceOptions.length }
            onChange={handleChangeSubservice}
            showSearch={true}
            filterOption={(inputValue, option) => {
              return (
                option?.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ?? false
              );
            }}
          >
            {subserviceOptions.map((option, index) => (
              <Select.Option key={index} value={option.id}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>


        <Form.Item
          label="Property"
          name="pagename"
          //  name={subserviceOptions.length  && subService!=32?"pagename":"pagename1"}
        //rules={[{ required: true, message: "Page name is required!" }]}
        >
          <Select
            showSearch
            disabled={(!subService && !row.pagename)  ||subService==32 || !subserviceOptions.length}
            onSearch={onSearch} 
            style={{ width: 315 }}
            value={propertyp}
            onChange={(value) =>{setValidStatus({ ...validStatus, pagesname: true });
            setPropertyp(value)} }
          >
            {propertyPages.map((pagesname, index) => (
              <Select.Option key={index} value={pagesname}>
                {pagesname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      </Form>
    </Modal>
  );
}