import React, { useEffect } from "react";
import { Modal, Alert, Divider, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { deleteProperty } from "./api";
import { RootState } from "../../App";
import { clearPropertiesStatus, hideDeletePropertyModal } from "./slice";

export default function DeleteModal(props: {
  row: { 
    name: string;
    retailerID: string;
    id: string;
    uniqueID: string;
    pageID: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    notes: string;
    serviceID: number;
    subserviceID: number;
    username: string;
    password: string;
    apiKey: string; };
}) {
  const { row } = props;
  const dispatch = useDispatch();
  const visible = useSelector(
    (state: RootState) => state.properties.showDeleteModal === row.id
  );
  const loading = useSelector(
    (state: RootState) => state.properties.deletePropertyLoading
  );
  const error = useSelector(
    (state: RootState) => state.properties.deletePropertyError
  );


  const {deleteSuccess,deleteError} = useSelector((state : RootState) => state.properties);

  
  useEffect(()=>{
    if(deleteSuccess){
      notification.success({
        message: 'Success Message',
        description: `${row.name} property deleted successfully.`
      })
      dispatch(clearPropertiesStatus())
    }
    if(deleteError){
      notification.error({
        message: 'Error Message',
        description: `Unable to delete ${row.name} property.`
      })
      dispatch(clearPropertiesStatus())
    }
  },[deleteError,deleteSuccess])

  // Handle Delete Button Click
  const handleDelete = () => {
    dispatch(deleteProperty(row));
  };

  // Handle Cancel Button Click
  const handleCancel = () => {
    dispatch(hideDeletePropertyModal());
  };

  return (
    <Modal
      maskClosable={false}
      title="Delete Property"
      visible={visible}
      onCancel={handleCancel}
      okText="Delete"
      onOk={handleDelete}
      okButtonProps={{ danger: true, loading: loading ? loading : undefined }}
      destroyOnClose={true}
    >
      {error && (
        <>
          <Alert message="Error" description={error} type="error" showIcon />
          <Divider />
        </>
      )}
      <h3>Are you sure you want to delete {row.name}?</h3>{" "}
    </Modal>
  );
}
