import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../App";
import { getEmailsReport } from "./api";
import { EmailsTable } from "./EmailsTable";
import { Quota } from "./Quota";
import { SelectDateInterval } from "./SelectDateInterval";

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export default () => {
  const dispatch = useDispatch();
  const permissions = useSelector(
    (state: RootState) => state.login.authData?.claims.Permission ?? []
  );

  const [firstRender, setFirstrender] = useState(true);

  const currentRetailer = useSelector(
    (Store: RootState) => Store.currentRetailer.currentRetailer
  );

  const dateInterval = useSelector(
    (Store: RootState) => Store.emails.dateInterval
  );

  useEffect(() => {
    setFirstrender(false);

    dispatch(getEmailsReport());
  }, [dispatch, permissions, currentRetailer, dateInterval]);

  return firstRender ? null : (
    <Container>
      <HeaderContainer>
        <h1>Emails</h1>
      </HeaderContainer>
      {permissions.find((permission) => permission === "IsAdministrator") ? (
        <Quota />
      ) : null}

      <SelectDateInterval />
      <EmailsTable />
    </Container>
  );
};
