import React from "react";
import styled, { keyframes } from "styled-components";

const motion = (p: any) => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const defaultProps = {
  width: 64,
  height: 64,
  size: 46,
  color: "#00bfff",
  sizeUnit: "px",
};

const DualRingSpinner = styled.div<typeof defaultProps>`
  display: inline-block;
  width: ${(p) => `${p.width}${p.sizeUnit}`};
  height: ${(p) => `${p.height}${p.sizeUnit}`};
  :after {
    content: " ";
    display: block;
    width: ${(p) => `${p.size}${p.sizeUnit}`};
    height: ${(p) => `${p.size}${p.sizeUnit}`};
    margin: 1px;
    border-radius: 50%;
    border: 5px solid ${(p) => p.color};
    border-color: ${(p) => p.color} transparent ${(p) => p.color} transparent;
    animation: ${(p) => motion(p)} 1.2s linear infinite;
  }
`;

export const DualRingLoader = () => <DualRingSpinner {...defaultProps} />;
